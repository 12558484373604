<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(-745 -1494.5)">
      <path
        class="a"
        d="M745.5,1494.5a.5.5,0,0,0-.5.5v14a.5.5,0,0,0,.5.5H747v-1h-1v-13h22v13h-8v1h8.5a.5.5,0,0,0,.5-.5v-14a.5.5,0,0,0-.5-.5Z"
      ></path>
      <path
        class="a"
        d="M760,1507.5h4.5a2.5,2.5,0,0,0,2.5-2.5v-6a2.5,2.5,0,0,0-2.5-2.5h-15a2.5,2.5,0,0,0-2.5,2.5v6a2.479,2.479,0,0,0,.262,1.093c.686-1.07,2.742-1.593,6.238-1.593,4.374,0,6.5.817,6.5,2.5Zm-10-7a1,1,0,1,1,1-1A1,1,0,0,1,750,1500.5Zm6.5,3.5a3,3,0,1,1,3-3A3,3,0,0,1,756.5,1504Zm7.5-.5a1,1,0,1,1-1,1A1,1,0,0,1,764,1503.5Z"
      ></path>
      <path
        class="a"
        d="M753.5,1516.5a22.6,22.6,0,0,1-5.5-.6v1.1c0,1.385,4.119,1.5,5.5,1.5s5.5-.115,5.5-1.5v-1.1A22.631,22.631,0,0,1,753.5,1516.5Z"
      ></path>
      <path
        class="a"
        d="M753.5,1508.5a22.6,22.6,0,0,1-5.5-.6v.936a23.01,23.01,0,0,0,11,0v-.936A22.631,22.631,0,0,1,753.5,1508.5Z"
      ></path>
      <path
        class="a"
        d="M758.978,1506.843c-.21-.856-2.179-1.343-5.478-1.343s-5.268.487-5.479,1.343a23.171,23.171,0,0,0,10.957,0Z"
      ></path>
      <path
        class="a"
        d="M753.5,1514.5a22.6,22.6,0,0,1-5.5-.6v.936a23.01,23.01,0,0,0,11,0v-.936A22.631,22.631,0,0,1,753.5,1514.5Z"
      ></path>
      <path
        class="a"
        d="M753.5,1510.5a22.6,22.6,0,0,1-5.5-.6v.936a23.01,23.01,0,0,0,11,0v-.936A22.631,22.631,0,0,1,753.5,1510.5Z"
      ></path>
      <path
        class="a"
        d="M753.5,1512.5a22.6,22.6,0,0,1-5.5-.6v.936a23.01,23.01,0,0,0,11,0v-.936A22.631,22.631,0,0,1,753.5,1512.5Z"
      ></path>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Balances',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
