import { IPayoutStore } from '@/models/interfaces/stores/IPayoutStore';

export default {
  state: {
    payoutList: null,
    payoutListTotal: 0,
    payoutDetailName: null,
  },
  getters: {
    payoutList: (state: IPayoutStore) => state.payoutList,
    payoutListTotal: (state: IPayoutStore) => state.payoutListTotal,
    payoutDetailName: (state: IPayoutStore) => state.payoutDetailName,
  },
  mutations: {
    SET_PAYOUT_DETAIL_NAME(state: IPayoutStore, name: string) {
      state.payoutDetailName = name;
    },
    SET_PAYOUT_LIST(state: IPayoutStore, payoutList: any[]) {
      state.payoutList = payoutList;
    },
    SET_PAYOUT_LIST_TOTAL(state: IPayoutStore, total: number) {
      state.payoutListTotal = total;
    },
    RESET(state: IPayoutStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        payoutList: [],
        payoutListTotal: 0,
        payoutDetailName: null,
      };
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    setPayoutList(context: any, data: any) {
      context.commit('SET_PAYOUT_LIST', data);
    },
    setPayoutListTotal(context: any, data: any) {
      context.commit('SET_PAYOUT_LIST_TOTAL', data);
    },
    setPayoutDetailName(context: any, name: string) {
      context.commit('SET_PAYOUT_DETAIL_NAME', name);
    },
  },
};
