// eslint-disable-next-line import/extensions
import MetamaskService from '@/plugins/metamask.service';
import {
  TRANSACTION_STATUS_INIT,
  TRANSACTION_STATUS_PENDING,
  TRANSACTION_STATUS_COMPLETE,
} from '@/helpers/constants';
import { errorHandler } from '@/helpers/errorHelper';
import { IMetamaskStore } from '@/models/interfaces/stores/IMetamaskStore';
import getMetaByChainID from '@/utils/aggregated-network-meta';

// TODO: Cover it by correct types
export default {
  state: {
    onboarding: null,
    web3: null,
    accounts: [],
    myBalance: 0,
    transactionStatus: TRANSACTION_STATUS_INIT,
    currentNetwork: null,
    transactionAmount: null,
    transactionAsset: null,
  },
  getters: {
    transactionAsset: (state: IMetamaskStore) => state.transactionAsset,
    transactionAmount: (state: IMetamaskStore) => state.transactionAmount,
    currentNetwork: (state: IMetamaskStore) => state.currentNetwork,
    onboarding: (state: IMetamaskStore) => state.onboarding,
    web3: (state: IMetamaskStore) => state.web3,
    isMetaMaskInstalled: () => MetamaskService.isMetaMaskInstalled(),
    isMetaMaskConnected: (state: IMetamaskStore) =>
      MetamaskService.isMetaMaskConnected(state),
    accounts: (state: IMetamaskStore) => state.accounts,
    transactionStatus: (state: IMetamaskStore) => state.transactionStatus,
  },
  mutations: {
    SET_CURRENT_NETWORK(state: IMetamaskStore, network: any) {
      state.currentNetwork = network;
    },
    SET_ONBOARDING(state: IMetamaskStore, onboarding: any) {
      state.onboarding = onboarding;
    },
    SET_WEB3(state: IMetamaskStore, web3: any) {
      state.web3 = web3;
    },
    SET_ACCOUNTS(state: IMetamaskStore, newAccounts: any[]) {
      if (newAccounts?.length > 0) {
        console.log('success', `Account ${newAccounts[0]} connected`);
      } else {
        console.log('error', `Account ${newAccounts[0]} disconnected`);
      }
      state.accounts = newAccounts;
    },
    SET_TRANSACTION_STATUS(state: IMetamaskStore, status: string) {
      state.transactionStatus = status;
    },
    SET_TRANSACTION_AMOUNT(state: IMetamaskStore, amount: string) {
      state.transactionAmount = amount;
    },
    SET_TRANSACTION_ASSET(state: IMetamaskStore, asset: string) {
      state.transactionAsset = asset;
    },
    RESET(state: IMetamaskStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        onboarding: null,
        web3: null,
        accounts: [],
        myBalance: 0,
        transactionStatus: TRANSACTION_STATUS_INIT,
        currentNetwork: null,
        transactionAmount: null,
        transactionAsset: null,
      };
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    setTransactionAmount(context: any, amountTransferred: string) {
      context.commit('SET_TRANSACTION_AMOUNT', amountTransferred);
    },
    setTransactionAsset(context: any, assetTransferred: string) {
      context.commit('SET_TRANSACTION_ASSET', assetTransferred);
    },
    setCurrentNetwork(context: any, chainId: number) {
      context.commit('SET_CURRENT_NETWORK', chainId);
    },
    async mm_init(context: any) {
      // console.log('init');
      const onboarding = MetamaskService.initOnboarding();
      context.commit('SET_ONBOARDING', onboarding);
      if (context.getters.isMetaMaskInstalled) {
        try {
          const web3 = await MetamaskService.initWeb3();
          context.commit('SET_WEB3', web3);

          const { ethereum } = window;
          ethereum.on('accountsChanged', (newAccounts: any) => {
            context.commit('SET_ACCOUNTS', newAccounts);
          });
          const newAccounts = await ethereum.request({
            method: 'eth_accounts',
          });
          if (newAccounts?.length <= 0) {
            console.log('error', 'Account is not connected');
            throw new Error('Something went wrong! Account is not connected');
          }
          context.commit('SET_ACCOUNTS', newAccounts);
        } catch (err) {
          if (process.env.VUE_APP_ENVIRONMENT === 'development')
            console.error('Error on init when get accounts', err);
          // throw new Error(
          //   'Something went wrong when trying to getting account'
          // );
        }
      }
    },
    async mm_connect(context: any) {
      // console.log('connect');
      try {
        const { ethereum } = window;
        const newAccounts = await ethereum.request({
          method: 'eth_requestAccounts',
        });
        context.commit('SET_ACCOUNTS', newAccounts);
      } catch (error: any) {
        if (process.env.VUE_APP_ENVIRONMENT === 'development')
          console.error(error);
        throw new Error('Something went wrong when trying to get account');
      }
    },
    async sendTransaction(context: any, data: any) {
      const fromAddress = context.state.accounts[0];
      let { amount } = data;
      const receiver = data.address;

      try {
        let transaction: any;
        if (data.decimals === 18 && !data.treatAsFiat) {
          const amountToSend = context.state.web3.utils.toWei(
            String(amount),
            'ether'
          );
          transaction = context.state.web3.eth.sendTransaction({
            from: fromAddress,
            to: receiver,
            value: amountToSend,
          });
        } else {
          const ABI = [
            {
              constant: false,
              inputs: [
                {
                  name: '_to',
                  type: 'address',
                },
                {
                  name: '_value',
                  type: 'uint256',
                },
              ],
              name: 'transfer',
              outputs: [
                {
                  name: '',
                  type: 'bool',
                },
              ],
              type: 'function',
            },
          ];
          amount *= 10 ** data.decimals;
          const contract = new context.state.web3.eth.Contract(
            ABI,
            data.tokenAddress,
            { from: fromAddress }
          );
          const amountToSend = amount.toFixed(0);
          transaction = contract.methods.transfer(receiver, amountToSend).send({
            from: context.state.accounts[0],
          });
        }

        context.commit('SET_TRANSACTION_STATUS', TRANSACTION_STATUS_PENDING);
        // should we store transaction var or transaction state for using after refreshing
        // page during transacrion handling proccess
        transaction
          .on('transactionHash', (hash: string) => {
            console.log('transactionHash', hash);
          })
          .on('confirmation', (confirmationNumber: any, receipt: any) => {
            console.log('confirmation', confirmationNumber, receipt);
            context.commit(
              'SET_TRANSACTION_STATUS',
              TRANSACTION_STATUS_COMPLETE
            );
          })
          .on('receipt', async (receipt: any) => {
            console.log('receipt', receipt);
            // RECEIVE TRANSACTION AMOUNT FROM METAMASK

            // const tr = await context.state.web3.eth.getTransaction(
            //   receipt.transactionHash
            // );
            // const amountTransferred = await context.state.web3.utils.fromWei(
            //   tr.value,
            //   'ether'
            // );
            // console.log(
            //   'Transaction confirmed! Amount transferred:',
            //   amountTransferred
            // );

            // context.commit('SET_TRANSACTION_AMOUNT', amountTransferred);
          })
          .on('error', (error: any) => {
            context.commit('SET_TRANSACTION_STATUS', TRANSACTION_STATUS_INIT);
            errorHandler(data.vm, error);
          });
      } catch (error: any) {
        context.commit('SET_TRANSACTION_STATUS', TRANSACTION_STATUS_INIT);
        errorHandler(data.vm, error);
      }
    },
    async changeNetwork(context: any, chainId: string) {
      // console.log('change');
      const { ethereum } = window;
      try {
        const currentNetworkVersion = await ethereum.request({
          method: 'net_version',
        });
        if (ethereum && currentNetworkVersion !== chainId) {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: context.state.web3.utils.toHex(chainId) }],
          });
        }
      } catch (err: any) {
        if (err.code === 4001) {
          if (process.env.VUE_APP_ENVIRONMENT === 'development')
            console.error('User closed MetaMask window during network switch');

          throw new Error('You closed MetaMask window during network switch');
        } else if (err.code === -32002) {
          if (process.env.VUE_APP_ENVIRONMENT === 'development')
            console.error('error_change_chain', err);

          throw new Error(
            'The network change request is already under review. Open your MetaMask window to accept or reject.'
          );
        } else if (err.code === 4902) {
          const meta = getMetaByChainID(Number(chainId));
          if (meta) {
            meta[0] = {
              ...meta[0],
              chainId: context.state.web3.utils.toHex(chainId),
            };

            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: meta,
            });
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (process.env.VUE_APP_ENVIRONMENT === 'development')
            console.error('error_change_chain', err);
          // Handle other errors
          // throw new Error('Something went wrong when selecting the network');
        }
      }
    },
  },
};
