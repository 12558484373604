import ApiBase from '@/api/baseApi';
import notify from '@/helpers/notifyHelper';
import { errorHandler, expandedErrorHandler } from '@/helpers/errorHelper';
import { IProfile } from '@/models/interfaces/profile/IProfile';

class UserAPI {
  static async getProfile(self: any) {
    return new Promise((res) => {
      self
        .http(
          'get',
          `${ApiBase.baseUrl()}/profile`,
          null,
          ApiBase.authHeaders()
        )
        .then((response: IProfile) => {
          ApiBase.setToStore(null, 'setUserDataList', response);
          res(response);
        })
        .catch((error: Error) => {
          errorHandler(self, error);
        });
    });
  }

  static async changeProject(self: any, projectId: string) {
    return new Promise((res) => {
      self
        .http(
          'patch',
          `${ApiBase.baseUrl()}/merchant-users/current-project/${projectId}`,
          null,
          ApiBase.authHeaders()
        )
        .then((response: IProfile) => {
          res(response);
        })
        .catch((error: Error) => {
          errorHandler(self, error);
        });
    });
  }

  static async closeAccount(self: any) {
    try {
      await self.http(
        'delete',
        `${ApiBase.baseUrl()}/profile`,
        null,
        ApiBase.authHeaders()
      );
      notify(self, 'success', 'The account have been closed');
      ApiBase.unauthoriseUser();
    } catch (error: any) {
      errorHandler(self, error);
    }
  }

  static verifyKYC(self: any) {
    return self
      .http(
        'post',
        `${ApiBase.baseUrl()}/profile/kyc`,
        self.form,
        ApiBase.authHeaders()
      )
      .then(() => {
        notify(self, 'success', 'Your data has been sent');
      })
      .catch((error: Error) => {
        expandedErrorHandler(self, error);
      });
  }
}

export default UserAPI;
