/* eslint-disable no-unused-vars */
import Vue from 'vue';

type ActionType =
  | {
      text: string;
      handler: any;
    }[]
  | null;
interface IComponentData {
  isVisible: boolean;
  item: {
    type: string;
    text: string;
  };
  timeout: number;
  actions: ActionType;
}

interface IComponentComputed {
  init: void;
}

interface IComponentMehods {
  init(type: string, text: string, actions: ActionType): void;
  close(): void;
  reset(): void;
}

interface IComponentProps {
  value: boolean;
}

export default Vue.extend<
  IComponentData,
  IComponentMehods,
  IComponentComputed,
  IComponentProps
>({
  name: 'Notification',
  data: () => ({
    isVisible: false,
    item: {
      type: '',
      text: '',
    },
    actions: null,
    timeout: 5000,
  }),
  methods: {
    init(type: string, text: string, actions: ActionType) {
      this.isVisible = true;
      this.actions = actions;
      this.item = {
        type,
        text,
      };

      setTimeout(() => this.close(), this.timeout);
    },
    close() {
      this.isVisible = false;
      this.reset();
    },
    reset() {
      this.item = {
        type: '',
        text: '',
      };
    },
  },
  created() {
    this.$root.$refs.$notify = this;
  },
});
