import { IWhitelistDomainStore } from '@/models/interfaces/stores/IWhitelistDomainStore';
import { IDomainData } from '@/models/interfaces/whitelistDomains/IWhitelistDomain';

export default {
  state: {
    whitelistDomains: [],
  },
  getters: {
    whitelistDomains: (state: IWhitelistDomainStore) => state.whitelistDomains,
  },
  mutations: {
    SET_WHITELIST_DOMAINS(state: IWhitelistDomainStore, list: IDomainData[]) {
      state.whitelistDomains = list;
    },
    DELETE_WHITELIST_DOMAIN(state: IWhitelistDomainStore, id: string) {
      state.whitelistDomains = state.whitelistDomains.filter(
        (item) => item.id !== id
      );
    },
    RESET(state: IWhitelistDomainStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        whitelistDomains: [],
      };
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    setWhitelistDomains(context: any, list: IDomainData[]) {
      context.commit('SET_WHITELIST_DOMAINS', list);
    },
    deleteWhitelistDomain(context: any, id: string) {
      context.commit('DELETE_WHITELIST_DOMAIN', id);
    },
  },
};
