import { IMerchantStore } from '@/models/interfaces/stores/IMerchantStore';

export default {
  state: {
    merchantsList: null,
    merchantsAggregateList: null,
  },
  getters: {
    merchantsAggregateList: (state: IMerchantStore) =>
      state.merchantsAggregateList &&
      state.merchantsAggregateList.sort(
        (a: any, b: any) => b.active - a.active
      ),
    merchantsList: (state: IMerchantStore) =>
      state.merchantsList &&
      state.merchantsList.sort(
        (a: any, b: any) => b.merchant_user.active - a.merchant_user.active
      ),
  },
  mutations: {
    SET_MERCHANT_AGGREGATE_LIST(state: IMerchantStore, merchants: any) {
      state.merchantsAggregateList = merchants;
    },
    SET_MERCHANT_LIST(state: IMerchantStore, merchants: any) {
      state.merchantsList = merchants;
    },
    RESET(state: IMerchantStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        merchantsList: null,
        merchantsAggregateList: null,
      };
    },
  },
  actions: {
    setMerchantAggregateList(context: any, merchants: any) {
      context.commit('SET_MERCHANT_AGGREGATE_LIST', merchants);
    },
    setMerchantList(context: any, merchants: any) {
      context.commit('SET_MERCHANT_LIST', merchants);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
