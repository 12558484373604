<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="a"
      d="M1008.853,472.146,1006,469.293a4.455,4.455,0,0,0,1-2.793,4.556,4.556,0,1,0-1.707,3.5l2.854,2.854a.5.5,0,0,0,.706-.708ZM1002.5,470a3.5,3.5,0,1,1,3.5-3.5A3.5,3.5,0,0,1,1002.5,470Z"
      transform="translate(-985 -449)"
    ></path>
    <path
      class="a"
      d="M999.348,471a5.5,5.5,0,0,1,2.652-9.974V454.5a.5.5,0,0,0-.147-.354l-5-5A.5.5,0,0,0,996.5,449h-11a.5.5,0,0,0-.5.5v21a.5.5,0,0,0,.5.5ZM996,450l5,5h-5Zm-6,4a2,2,0,1,1-2,2A2,2,0,0,1,990,454Zm-1.5,13a.5.5,0,0,1-.447-.724l2.5-5a.5.5,0,0,1,.8-.13l1.528,1.528,2.681-4.913a.5.5,0,0,1,.917.1l1.6,3.889A6.442,6.442,0,0,0,996.025,467Z"
      transform="translate(-985 -449)"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'File',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
