<template>
  <svg
    class="a"
    xmlns="http://www.w3.org/2000/svg"
    width="14.143"
    height="14.144"
    viewBox="0 0 14.143 14.144"
  >
    <g transform="translate(-1150.781 -734.283)">
      <rect
        class="a"
        width="5"
        height="15"
        rx="1"
        transform="translate(1161.389 734.283) rotate(45)"
      ></rect>
      <rect
        class="a"
        width="5"
        height="15.001"
        rx="1"
        transform="translate(1164.924 744.891) rotate(135)"
      ></rect>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Error',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
