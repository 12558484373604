<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <path
      class="a"
      d="M4,22a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0H18a4,4,0,0,1,4,4V18a4,4,0,0,1-4,4ZM2,4V18a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4Zm3,7.071L6.414,9.657l2.829,2.829L14.9,6.828l1.414,1.414-7.07,7.071Z"
      transform="translate(0 0)"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'CheckboxOn',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: var(--v-gray-base);
  }
</style>
