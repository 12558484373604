import StorageHelper from '@/helpers/localStorageHelper';
import ApiBase from '@/api/baseApi';

interface IfNotAuthParams {
  next: any;
}

export default function ifNotAuth({ next }: IfNotAuthParams) {
  if (StorageHelper.get(ApiBase.authTokenKey())) {
    return next({
      name: 'Dashboard',
    });
  }
  return next();
}
