<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <path
      class="a"
      d="M4 22C2.93913 22 1.92172 21.5786 1.17157 20.8284C0.421427 20.0783 0 19.0609 0 18V4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0L18 0C19.0609 0 20.0783 0.421427 20.8284 1.17157C21.5786 1.92172 22 2.93913 22 4V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H4ZM2 4V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V4C20 3.46957 19.7893 2.96086 19.4142 2.58579C19.0391 2.21071 18.5304 2 18 2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4Z"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'CheckboxOff',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: var(--v-gray-base);
  }
</style>
