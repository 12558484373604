import { ICallbacksStore } from '@/models/interfaces/stores/ICallbacksStore';
import { IApiKeys, ICallbacks } from '@/models/interfaces/keys/IKeys';

export default {
  state: {
    failedCallbacks: { count: 0, data: [] },
    callbacks: { count: 0, data: [] },
    apiKeys: [],
  },
  getters: {
    failedCallbacks: (state: ICallbacksStore) => state.failedCallbacks,
    callbacks: (state: ICallbacksStore) => state.callbacks,
    apiKeys: (state: ICallbacksStore) => state.apiKeys,
  },
  mutations: {
    SET_FAILED_CALLBACKS(state: ICallbacksStore, data: ICallbacks) {
      state.failedCallbacks = data;
    },
    SET_CALLBACKS(state: ICallbacksStore, data: ICallbacks) {
      state.callbacks = data;
    },
    SET_API_KEYS_LIST(state: ICallbacksStore, data: IApiKeys[]) {
      state.apiKeys = data;
    },
    RESET(state: ICallbacksStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        failedCallbacks: { count: 0, data: [] },
        callbacks: { count: 0, data: [] },
        apiKeys: [],
      };
    },
  },
  actions: {
    setApiKeys(context: any, data: IApiKeys) {
      context.commit('SET_API_KEYS_LIST', data);
    },
    setFailedCallbacks(context: any, data: ICallbacks) {
      context.commit('SET_FAILED_CALLBACKS', data);
    },
    setCallbacks(context: any, data: ICallbacks) {
      context.commit('SET_CALLBACKS', data);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
