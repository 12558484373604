import { render, staticRenderFns } from "./Withdraws.vue?vue&type=template&id=71d1e164&scoped=true"
import script from "./Withdraws.vue?vue&type=script&lang=js"
export * from "./Withdraws.vue?vue&type=script&lang=js"
import style0 from "./Withdraws.vue?vue&type=style&index=0&id=71d1e164&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d1e164",
  null
  
)

export default component.exports