/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import Vue from 'vue';
import axios from 'axios';
import notify from '@/helpers/notifyHelper';
import ApiBase from '@/api/baseApi';
import AuthAPI from '@/api/authApi';
import StorageHelper from '@/helpers/localStorageHelper';
import SlackNotifier from '@/utils/slack-notifier';

const refreshingTokenProcessKey = 'refreshing_token_process';
const hiddenDevToolsLSKey = 'hidden_dev_tools';
export interface IAxios extends Vue {
  checkToken: () => Promise<any>;
  checkTokenRefreshingProcess: () => Promise<IAxios>;
  httpLongPooling: (
    self: any,
    url: string,
    params: any,
    header: any,
    attempts: number
  ) => Promise<any>;
}

type Methods = 'post' | 'get' | 'put' | 'delete' | 'patch';

Vue.mixin({
  methods: {
    sleep(sencond: number) {
      return new Promise((res) => {
        setTimeout(() => res(true), sencond * 1000);
      });
    },
    checkTokenRefreshingProcess() {
      const self: IAxios = this as IAxios;
      let refreshingTokenProcess: any = null;
      let attempts = 100;

      return new Promise((resolve, reject) => {
        refreshingTokenProcess = window.setInterval(async () => {
          attempts -= 1;
          if (attempts <= 0) {
            if (refreshingTokenProcess)
              window.clearTimeout(refreshingTokenProcess);

            reject(new Error('Something wrong with refreshing the token'));
          }
          const isRefreshingTokenProcess = StorageHelper.get(
            refreshingTokenProcessKey
          );

          if (!isRefreshingTokenProcess) {
            // Clear refreshing token interval
            if (refreshingTokenProcess)
              window.clearTimeout(refreshingTokenProcess);
            // Remove refresh token process marker from storage
            StorageHelper.remove(refreshingTokenProcessKey);
            resolve(self);
          }
        }, 100);
      });
    },
    checkToken() {
      const authTokenTimeFromLS = StorageHelper.get(AuthAPI.authTokenTimeKey());
      let tokenTimeout: any = null;

      return new Promise((resolve, reject) => {
        if (authTokenTimeFromLS) {
          if (authTokenTimeFromLS <= new Date().getTime()) {
            // Set `refreshing_token_process` field local storage as true
            // To prevent the token from refreshing multiple times if there is more than 1 request per page
            StorageHelper.set(refreshingTokenProcessKey, true);

            // Clear token timeout
            if (tokenTimeout) window.clearTimeout(tokenTimeout);
            tokenTimeout = window.setTimeout(() => {
              axios({
                method: 'post',
                url: `${ApiBase.baseUrl()}/token`,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  ...ApiBase.authHeaders(),
                },
              })
                .then((response) => {
                  AuthAPI.setAuthToken(response.data.token).then(() => {
                    resolve(true);
                  });
                })
                .catch((error: Error) => {
                  if (process.env.VUE_APP_ENVIRONMENT === 'development')
                    console.error(error);
                  ApiBase.unauthoriseUser();
                  reject(error);
                })
                .finally(() => {
                  StorageHelper.remove(refreshingTokenProcessKey);
                });
            }, 1000);
          } else {
            StorageHelper.remove(refreshingTokenProcessKey);
            resolve(true);
          }

          // resolve(true);
        }
      });
    },

    async http(
      method: Methods,
      url: string,
      params: any,
      header: any,
      options?: any
    ) {
      const hdt = StorageHelper.get(hiddenDevToolsLSKey);
      if (hdt && hdt === 1) {
        console.time(`[${url}] Request time`);
      }
      if (header && header.Authorization) {
        await (this as IAxios)
          .checkTokenRefreshingProcess()
          .catch((err) => {
            throw new Error(err);
          })
          .then((self) =>
            self.checkToken().catch((err) => {
              throw new Error(err);
            })
          )
          .then(() => {
            header = {
              ...header,
              ...ApiBase.authHeaders(),
            };
          });
      }

      method = method.toLowerCase() as Methods;
      const config: any = {
        method,
        url,
      };

      if (['put', 'get'].includes(method)) {
        config.params = params;
      } else if (['post', 'delete', 'patch'].includes(method)) {
        if (options?.json) {
          config.data = params;
        } else {
          const form = new FormData();
          for (const field in params) {
            if (!params[field]) continue;
            if (
              typeof params[field] === 'object' &&
              !(params[field] instanceof File)
            ) {
              Object.keys(params[field]).forEach((key) => {
                form.append(`${field}[${key}]`, params[field][key]);
              });
            } else {
              form.append(field, params[field]);
            }
          }

          config.data = form;
        }
      }

      const contentType = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };

      config.headers = Object.assign(header || {}, contentType);
      const self: IAxios = this as IAxios;
      return new Promise((resolve, reject) =>
        // eslint-disable-next-line no-promise-executor-return
        axios(config)
          .then((response) => resolve(response.data))
          .catch((error: any) => {
            if (error?.message === 'Network Error') {
              reject();
              return;
            }
            if (error.response) {
              switch (error.response.status) {
                case 400:
                  reject(error.response.data);
                  break;
                case 413:
                  // eslint-disable-next-line prefer-promise-reject-errors
                  reject({
                    message: 'The file is too large',
                  });
                  break;
                case 401:
                  reject(error.response.data);
                  ApiBase.unauthoriseUser();
                  break;
                case 404:
                  if (
                    !['SignIn', 'Exchange'].includes(
                      self.$route.name as string
                    ) &&
                    process.env.VUE_APP_ENVIRONMENT !== 'development'
                  )
                    SlackNotifier.sendToSlack(
                      `Error 404: ${error} \nMethod: ${method}\nEndpoint: ${url} \nParams: ${JSON.stringify(
                        params
                      )}\nHeader: ${JSON.stringify(header)}`
                    );
                  reject(error.response.data);
                  break;
                case 500:
                  if (
                    !['SignIn', 'Exchange'].includes(
                      self.$route.name as string
                    ) &&
                    process.env.VUE_APP_ENVIRONMENT !== 'development'
                  )
                    SlackNotifier.sendToSlack(
                      `Error 500: ${error} \nMethod: ${method}\nEndpoint: ${url} \nParams: ${JSON.stringify(
                        params
                      )}\nHeader: ${JSON.stringify(header)}`
                    );
                  reject(error);
                  break;
                default:
                  if (process.env.VUE_APP_ENVIRONMENT === 'development')
                    console.error('error', error.response);
                  reject(error);
                  break;
              }
            } else {
              if (process.env.VUE_APP_ENVIRONMENT === 'development') {
                console.error(error);
                notify(this, 'error', error);
              }
              reject(error);
            }
          })
          .finally(() => {
            if (hdt && hdt === 1) {
              console.timeEnd(`[${url}] Request time`);
            }
          })
      );
    },

    async httpLongPooling(
      self: any,
      url: string,
      params: any,
      header: any,
      attempts = 0
    ) {
      if (header && header.Authorization) {
        await (this as IAxios)
          .checkTokenRefreshingProcess()
          .catch((err) => {
            throw new Error(err);
          })
          .then((that) =>
            that.checkToken().catch((err) => {
              throw new Error(err);
            })
          )
          .then(() => {
            header = {
              ...header,
              ...ApiBase.authHeaders(),
            };
          });
      }

      ApiBase.setToStore(null, 'setReportLoaded', false);
      ApiBase.setToStore(null, 'setReportMessage', null);

      const that = this as any;

      const config: any = {
        method: 'get',
        url,
        params,
        responseType: 'blob',
        headers: Object.assign(header || {}, {
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };

      return new Promise((resolve, reject) =>
        // eslint-disable-next-line no-promise-executor-return
        axios(config)
          .then(async (response) => {
            // let contentDisposition;
            const reportPrefixStrong = params.id || params.filename || 'report';
            const reportPeriodString = self.state.reportPeriod
              ? `-${self.state.reportPeriod}`
              : '';

            const filename = `${reportPrefixStrong}${reportPeriodString}`;
            let urlObject;
            let link;
            let contentType;
            let blobData;
            let fileExtension;

            switch (response.status) {
              case 200:
                contentType = response.headers['content-type'];
                if (
                  contentType ===
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ) {
                  // XLSX format
                  blobData = new Blob([response.data], { type: contentType });
                  fileExtension = '.xlsx';
                } else if (contentType.includes('text/csv')) {
                  // CSV format
                  blobData = new Blob([response.data], { type: contentType });
                  fileExtension = '.csv';
                } else if (contentType === 'application/pdf') {
                  // PDF format
                  blobData = new Blob([response.data], { type: contentType });
                  fileExtension = '.pdf';
                } else {
                  if (process.env.VUE_APP_ENVIRONMENT === 'development')
                    console.error('Unsupported file format');
                  return;
                }

                urlObject = window.URL.createObjectURL(blobData);

                link = document.createElement('a');
                link.href = urlObject;
                link.setAttribute('download', filename + fileExtension);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                ApiBase.setToStore(null, 'setReportLoaded', true);
                ApiBase.setToStore(
                  null,
                  'setReportMessage',
                  'Report created successfully'
                );
                resolve('Report is generated');
                break;
              case 204:
                if (attempts <= 60) {
                  await that.sleep(2);
                  that.httpLongPooling(self, url, params, header, attempts + 1);
                } else {
                  ApiBase.setToStore(null, 'setReportLoaded', true);
                  ApiBase.setToStore(
                    null,
                    'setReportMessage',
                    'Report created successfully'
                  );
                  // reject(new Error('Something went wrong'));
                  reject();
                }
                break;
              default:
                break;
            }
          })
          .catch(async (error: any) => {
            switch (error.response.status) {
              case 404:
              case 500:
                if (attempts <= 60) {
                  await that.sleep(2);
                  that.httpLongPooling(self, url, params, header, attempts + 1);
                } else {
                  ApiBase.setToStore(null, 'setReportLoaded', true);
                  ApiBase.setToStore(
                    null,
                    'setReportMessage',
                    'An error occurred while trying to download the report'
                  );
                  notify(
                    self,
                    'error',
                    'An error occurred while trying to download the report'
                  );
                  SlackNotifier.sendToSlack(`Long Pooling Error: ${error}`);
                  // reject(new Error('Something went wrong'));
                  reject();
                }
                break;
              case 422:
                ApiBase.setToStore(null, 'setReportLoaded', true);
                ApiBase.setToStore(
                  null,
                  'setReportMessage',
                  'An error occurred while trying to download the report'
                );
                notify(
                  self,
                  'error',
                  'An error occurred while trying to download the report'
                );
                // reject(new Error('Something went wrong'));
                reject();
                break;
              default:
                ApiBase.setToStore(null, 'setReportLoaded', true);
                ApiBase.setToStore(
                  null,
                  'setReportMessage',
                  'An error occurred while trying to download the report'
                );
                notify(
                  self,
                  'error',
                  'An error occurred while trying to download the report'
                );
                reject(error);
                break;
            }
          })
          .finally(() => {
            ApiBase.setToStore(null, 'setReportType', null);
          })
      );
    },
  },
});
