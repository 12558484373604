import { IVolumeStore } from '@/models/interfaces/stores/IVolumeStore';

// TODO: Check and make correct types here
export default {
  state: {
    volumeList: null,
  },
  getters: {
    volumeList: (state: IVolumeStore) => state.volumeList,
  },
  mutations: {
    SET_VOLUME_LIST(state: IVolumeStore, list: any[]) {
      state.volumeList = list;
    },
    RESET(state: IVolumeStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        volumeList: [],
      };
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    setVolumeList(context: any, list: any) {
      context.commit('SET_VOLUME_LIST', list);
    },
  },
};
