<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="24.002"
    viewBox="0 0 17 24.002"
  >
    <path
      class="a"
      d="M1149.147,246.647l-1.647,1.646-1.647-1.646a.5.5,0,0,0-.706,0l-1.647,1.646-1.647-1.646a.5.5,0,0,0-.706,0l-1.647,1.646-1.647-1.646a.5.5,0,0,0-.706,0l-1.647,1.646-1.647-1.646A.5.5,0,0,0,1133,247v23a.5.5,0,0,0,.853.354l1.647-1.647,1.647,1.647a.5.5,0,0,0,.706,0l1.647-1.647,1.647,1.647a.5.5,0,0,0,.706,0l1.647-1.647,1.647,1.647a.5.5,0,0,0,.706,0l1.647-1.647,1.647,1.647A.5.5,0,0,0,1150,270V247A.5.5,0,0,0,1149.147,246.647ZM1139.5,262.5h-3a.5.5,0,0,1,0-1h3a.5.5,0,0,1,0,1Zm2-3h-5a.5.5,0,0,1,0-1h5a.5.5,0,0,1,0,1ZM1136,256a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,0,1h-4A.5.5,0,0,1,1136,256Zm6.5-2.5h-6a.5.5,0,0,1,0-1h6a.5.5,0,0,1,0,1Zm4,9h-1a.5.5,0,0,1,0-1h1a.5.5,0,0,1,0,1Zm0-3h-1a.5.5,0,0,1,0-1h1a.5.5,0,0,1,0,1Zm0-3h-1a.5.5,0,0,1,0-1h1a.5.5,0,0,1,0,1Zm0-3h-1a.5.5,0,0,1,0-1h1a.5.5,0,0,1,0,1Z"
      transform="translate(-1133 -246.499)"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Payments',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
