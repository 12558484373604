<template>
  <v-app id="app">
    <router-view></router-view>
    <Notification />
    <LoadNotification />
    <!-- <CountDownNotification /> -->
  </v-app>
</template>

<script>
  import Notification from '@/components/notification/Notification.vue';
  import LoadNotification from '@/components/load-notification/LoadNotification.vue';
  // import CountDownNotification from '@/components/count-down-notification/CountDownNotification.vue';

  export default {
    name: 'App',
    components: {
      Notification,
      LoadNotification,
      // CountDownNotification,
    },
  };
</script>
