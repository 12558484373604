import RouterComponent from '@/components/router/Router.vue';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: () => import('@/pages/forgot-password/ForgotPassword.vue'),
    },
  ],
};
