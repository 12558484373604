export function loadNotify(that: any, response: string) {
  that.$root.$refs.$loadNotify.init(response);
}

export function loadedNotify(that: any, response: string) {
  that.$root.$refs.$loadNotify.loaded(response);
}

export function closeLoadNotify(that: any) {
  that.$root.$refs.$loadNotify.close();
}

export default loadNotify;
