<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(-433 -783)">
      <circle
        class="a"
        cx="3"
        cy="3"
        r="3"
        transform="translate(442 792.5)"
      ></circle>
      <path
        class="a"
        d="M452.5,783h-15a4.505,4.505,0,0,0-4.5,4.5v15a4.5,4.5,0,0,0,4.5,4.5h15a4.5,4.5,0,0,0,4.5-4.5v-15A4.505,4.505,0,0,0,452.5,783Zm-.192,14.735-1.75,3.031a.5.5,0,0,1-.682.183l-1.247-.72a6.056,6.056,0,0,1-1.44.83V802.5a.5.5,0,0,1-.5.5h-3.5a.5.5,0,0,1-.5-.5v-1.445a5.964,5.964,0,0,1-1.438-.829l-1.252.723a.5.5,0,0,1-.682-.183l-1.75-3.031a.5.5,0,0,1,.182-.683l1.253-.724a5.977,5.977,0,0,1,0-1.658l-1.25-.721a.5.5,0,0,1-.184-.683l1.75-3.031a.5.5,0,0,1,.684-.183l1.247.72a5.989,5.989,0,0,1,1.441-.83V788.5a.5.5,0,0,1,.5-.5h3.5a.5.5,0,0,1,.5.5v1.444a5.944,5.944,0,0,1,1.437.829l1.248-.721a.5.5,0,0,1,.684.183l1.75,3.031a.5.5,0,0,1-.184.683l-1.247.72a6.007,6.007,0,0,1,0,1.662l1.248.721A.5.5,0,0,1,452.308,797.735Z"
      ></path>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Settings',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
