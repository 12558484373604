import RouterComponent from '@/components/router/Router.vue';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '*',
      name: 'Not found',
      component: () => import('@/pages/not-found/NotFound.vue'),
    },
  ],
};
