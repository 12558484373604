import RouterComponent from '@/components/router/Router.vue';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/reports/:id',
      name: 'Report',
      component: () => import('@/pages/report/Report.vue'),
    },
  ],
};
