import { ITransactionStore } from '@/models/interfaces/stores/ITransactionStore';
import {
  ITransactionData,
  ITransactionTotalData,
} from '@/models/interfaces/transaction/ITransaction';

export default {
  state: {
    transactionList: null,
    transactionTotal: {
      count: 0,
    },
  },
  getters: {
    transactionList: (state: ITransactionStore) => state.transactionList,
    transactionTotal: (state: ITransactionStore) => state.transactionTotal,
  },
  mutations: {
    SET_LIVE_TRANSACTION_LIST(
      state: ITransactionStore,
      list: ITransactionData[]
    ) {
      state.transactionList = list;
    },
    SET_TRANSACTION_TOTAL(
      state: ITransactionStore,
      data: ITransactionTotalData
    ) {
      state.transactionTotal = data;
    },
    RESET(state: ITransactionStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        transactionList: [],
        transactionTotal: {
          count: 0,
        },
      };
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    setLiveTransactionList(context: any, list: ITransactionData[]) {
      context.commit('SET_LIVE_TRANSACTION_LIST', list);
    },
    setTransactionTotal(context: any, data: ITransactionTotalData) {
      context.commit('SET_TRANSACTION_TOTAL', data);
    },
  },
};
