import { IBaseStore } from '@/models/interfaces/stores/IBaseStore';

export default {
  state: {
    loading: false,
    currentLang: 'en',
  },
  getters: {
    loading: (state: IBaseStore) => state.loading,
    currentLang: (state: IBaseStore) => state.currentLang,
  },
  mutations: {
    SET_LOADING(state: IBaseStore, value: boolean) {
      state.loading = value;
    },
    SET_CURRENT_LANG(state: IBaseStore, lang: string) {
      state.currentLang = lang;
    },
    RESET(state: IBaseStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        loading: false,
        currentLang: 'en',
      };
    },
  },
  actions: {
    setLoading(context: any, value: boolean) {
      context.commit('SET_LOADING', value);
    },
    setCurrentLang(context: any, lang: string) {
      context.commit('SET_CURRENT_LANG', lang);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
