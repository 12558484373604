import {
  IDividendsList,
  IResponseDividendsShares,
  IResponseDividendsSource,
  IResponseDividendsSourceAvailable,
  IResponseDividendsSpreads,
} from '@/models/interfaces/dividends/IDividends';
import { IDividendsStore } from '@/models/interfaces/stores/IDividendsStore';

export default {
  state: {
    dividendsShareList: [],
    dividendsSourceList: [],
    dividendsSpreadList: [],
    dividendsSourceAvailalbeList: [],
    dividendsShareTotal: 0,
    dividendsSourceTotal: 0,
    dividendsSpreadTotal: 0,
    dividendsSourceAvailalbeTotal: 0,

    dividendExpandedSource: [],
  },
  getters: {
    dividendExpandedSource: (state: IDividendsStore) =>
      state.dividendExpandedSource,
    dividendsShareList: (state: IDividendsStore) => state.dividendsShareList,
    dividendsSourceList: (state: IDividendsStore) => state.dividendsSourceList,
    dividendsSpreadList: (state: IDividendsStore) => state.dividendsSpreadList,
    dividendsSourceAvailalbeList: (state: IDividendsStore) =>
      state.dividendsSourceAvailalbeList,

    dividendsShareTotal: (state: IDividendsStore) => state.dividendsShareTotal,
    dividendsSourceTotal: (state: IDividendsStore) =>
      state.dividendsSourceTotal,
    dividendsSpreadTotal: (state: IDividendsStore) =>
      state.dividendsSpreadTotal,
    dividendsSourceAvailalbeTotal: (state: IDividendsStore) =>
      state.dividendsSourceAvailalbeTotal,
  },
  mutations: {
    SET_DIVIDEND_EXPANDED_SOURCE(state: IDividendsStore, data: any) {
      const index = state.dividendExpandedSource.findIndex(
        (s: any) => s.index === data.index
      );

      if (index === -1) {
        state.dividendExpandedSource.push(data);
      }
    },
    REMOVE_DIVIDEND_EXPANDED_SOURCE(state: IDividendsStore, data: any) {
      const index = state.dividendExpandedSource.findIndex(
        (s: any) => s.index === data.index
      );

      if (index > -1) {
        state.dividendExpandedSource.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    REMOVE_ALL_DIVIDEND_EXPANDED_SOURCE(state: IDividendsStore) {
      state.dividendExpandedSource = [];
    },
    SET_DIVIDEND_SHARE(state: IDividendsStore, data: IResponseDividendsShares) {
      state.dividendsShareList = data.data;
      state.dividendsShareTotal = data.total || 0;
    },
    SET_DIVIDEND_SOURCE_AVAILABLE(
      state: IDividendsStore,
      data: IResponseDividendsSourceAvailable
    ) {
      state.dividendsSourceAvailalbeList = data.data;
      state.dividendsSourceAvailalbeTotal = data?.total || 0;
    },
    SET_DIVIDEND_SOURCE(state: IDividendsStore, data: IDividendsList[]) {
      state.dividendsSourceList = data;
    },
    SET_DIVIDEND_SOURCE_TOTAL(state: IDividendsStore, data: number) {
      state.dividendsSourceTotal = data || 0;
    },
    SET_DIVIDEND_SPREAD(
      state: IDividendsStore,
      data: IResponseDividendsSpreads
    ) {
      state.dividendsSpreadList = data.data;
      state.dividendsSpreadTotal = data.total || 0;
    },
    RESET(state: IDividendsStore) {
      // eslint-disable-next-line no-unused-vars
      state.dividendsShareList = [];
      state.dividendsSourceList = [];
      state.dividendsSpreadList = [];
      state.dividendsSourceAvailalbeList = [];
      state.dividendsShareTotal = 0;
      state.dividendsSourceTotal = 0;
      state.dividendsSpreadTotal = 0;
      state.dividendsSourceAvailalbeTotal = 0;

      state.dividendExpandedSource = [];
    },
  },
  actions: {
    setDividendExpandedSource(context: any, value: string) {
      context.commit('SET_DIVIDEND_EXPANDED_SOURCE', value);
    },
    removeDividendExpandedSource(context: any, value: string) {
      context.commit('REMOVE_DIVIDEND_EXPANDED_SOURCE', value);
    },
    removeAllDividendExpandedSource(context: any, value: null) {
      context.commit('REMOVE_ALL_DIVIDEND_EXPANDED_SOURCE', value);
    },
    setDividendShare(context: any, value: IResponseDividendsShares) {
      context.commit('SET_DIVIDEND_SHARE', value);
    },
    setDividendSource(context: any, value: IResponseDividendsSource) {
      context.commit('SET_DIVIDEND_SOURCE', value);
    },
    setDividendSourceTotal(context: any, value: IResponseDividendsSource) {
      context.commit('SET_DIVIDEND_SOURCE_TOTAL', value);
    },
    setDividendSourceAvailable(
      context: any,
      value: IResponseDividendsSourceAvailable
    ) {
      context.commit('SET_DIVIDEND_SOURCE_AVAILABLE', value);
    },
    setDividendSpread(context: any, value: IResponseDividendsSpreads) {
      context.commit('SET_DIVIDEND_SPREAD', value);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
