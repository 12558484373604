/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Activator from './activator/Activator.vue';
import List from './list/List.vue';

interface IComponentData {
  menu: boolean;
  filteredOptions: any | null;
  filteredPrompt: string;
  filteredPromptTimer: number | null;
}

interface IComponentComputed {
  selectedItem: any;
  selectedItemText: any;
  activatorData: {
    height: any;
    width: any;
    selectedItem: any;
    menu: any;
    icon: any;
    type: any;
    label: any;
    selectedItemText: any;
    hightlightActive: boolean;
    hideChoice: boolean;
  };
}

interface IComponentMehods {
  filterOptions(event: any): void;
  selectItem(item: any): void;
}

interface IComponentProps {
  fullWidth: boolean;
  items: any[];
  value: any;
  label: string;
  width: string;
  height: string;
  icon: boolean;
  type: string;
  returnObject: boolean;
  rounded: boolean;
  disabled: boolean;
  hightlightActive: boolean;
  bullets: boolean;
  afterMerchantListText: string;
  hideChoice: boolean;
  contentHeight?: string;
  upperCase?: boolean;
  textTransformNone?: boolean;
  still?: boolean;
  contentClass?: string;
  scrollable: boolean;
}

export default Vue.extend<
  IComponentData,
  IComponentMehods,
  IComponentComputed,
  IComponentProps
>({
  name: 'CustomSelector',
  components: {
    Activator,
    List,
  },
  props: {
    afterMerchantListText: {
      type: String,
      default: '',
    },
    bullets: {
      type: Boolean,
      default: false,
    },
    hightlightActive: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: [] as any[],
    },
    value: {
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: '40',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'first',
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideChoice: {
      type: Boolean,
      default: false,
    },
    contentHeight: {
      type: String,
      default: null,
    },
    upperCase: {
      type: Boolean,
      default: false,
    },
    textTransformNone: {
      type: Boolean,
      default: false,
    },
    still: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: null,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: false,
    filteredOptions: null,
    filteredPrompt: '',
    filteredPromptTimer: null,
  }),
  updated() {
    if (this.menu) {
      document.addEventListener('keydown', this.filterOptions);
    } else {
      this.filteredOptions = null;
      document.removeEventListener('keydown', this.filterOptions);
    }
  },
  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        if (typeof value === 'string' && value.toLowerCase() === 'default') {
          this.$emit('input', null);
        } else {
          this.$emit('input', value);
        }
      },
    },
    selectedItemText() {
      if (this.items?.length <= 0 || !this.selectedItem) return this.label;
      const foundedItem = this.items.find((item) => {
        if (
          (item.value &&
            item.value ===
              (this.returnObject
                ? this.selectedItem.value
                : this.selectedItem)) ||
          (item.text &&
            item.text ===
              (this.returnObject ? this.selectedItem.text : this.selectedItem))
        )
          return true;

        return false;
      });

      if (!foundedItem) return this.label;
      return foundedItem.text;
    },
    activatorData() {
      const {
        height,
        width,
        selectedItem,
        menu,
        icon,
        type,
        label,
        selectedItemText,
        hightlightActive,
        hideChoice,
      } = this;

      return {
        height,
        width,
        selectedItem,
        menu,
        icon,
        type,
        label,
        selectedItemText,
        hightlightActive,
        hideChoice,
      };
    },
  },
  methods: {
    filterOptions(event) {
      if (this.filteredPromptTimer)
        window.clearTimeout(this.filteredPromptTimer);
      const typedLetters = event.key;

      if (!this.isLetter(typedLetters)) {
        this.filteredOptions = null;
        return;
      }

      this.filteredPrompt += typedLetters;

      // Filter options that match the typed letters
      this.filteredOptions = this.items.filter((item: any) =>
        item.text.toLowerCase().startsWith(this.filteredPrompt)
      );

      if (!this.filteredOptions?.length) {
        this.filteredOptions = null;
      }

      this.filteredPromptTimer = window.setTimeout(() => {
        this.filteredPrompt = '';
      }, 1000);
    },
    selectItem(item) {
      if (this.returnObject) {
        this.selectedItem = item;
      } else {
        this.selectedItem = item.value || item.text || item;
      }

      this.menu = false;
    },
  },
});
