<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 384"
  >
    <g
      id="Layer_2"
      data-name="Layer 2"
    >
      <g
        id="Layer_1-2"
        data-name="Layer 1"
      >
        <path
          class="a"
          d="M173.12,264.73q0,36,0,72c0,6.43-2.47,8.9-8.64,8.16-63.71-7.63-110.48-40-136.7-98.68C-10.36,160.91,28.92,63,114.5,25.92c67.67-29.29,148.86-8.56,194.68,49.62a168.17,168.17,0,0,1,35.76,87.93c.71,6.73-1.5,9.21-8.31,9.22q-70.94,0-141.85,0c-6.1,0-11.62,1.29-16.05,5.78-4.21,4.27-5.64,9.53-5.63,15.39Q173.18,229.29,173.12,264.73Z"
        ></path>
        <path
          class="a"
          d="M211.56,291.37q0-35.46,0-70.91c0-7.6,1.65-9.25,9.23-9.25H361.92c7.91,0,9.79,2.39,9,10.32-3.39,33.36-15.37,63.22-36.65,89.08q-40.87,49.65-104.6,59.26c-2.84.42-5.69.72-8.53,1.09-6.88.88-9.56-1.41-9.57-8.32Q211.53,327,211.56,291.37Zm25.64,12c.53,7,3.88,14.2,11.16,18.31,5.19,2.93,9.34,5.5,8,12.25a1.67,1.67,0,0,0,.2.72c1.08,3.11,3.27,4.68,6.54,4.41a5.73,5.73,0,0,0,5.67-5.44,24.17,24.17,0,0,0,.11-5.24c-.18-2.11.68-3,2.62-3.76,11.64-4.64,18.35-16.2,16.32-27.71-2.25-12.73-11.8-21-24.91-21.69-7.64-.37-12.95-5.54-13-12.67a12.59,12.59,0,0,1,11.58-12.85c7.42-.51,13,4.39,13.89,12.12.53,4.79,3,7.21,7.08,6.82,3.76-.37,6-3.32,5.63-7.84-.71-8.5-4.32-15.79-11.85-19.68-5.33-2.76-8.33-5.42-7.6-11.8.34-3-2.26-4.88-5.37-5.18s-5.67,1.12-6.59,4.28a16.15,16.15,0,0,0-.47,5.55c.17,2.57-.58,3.85-3.2,4.89a25.28,25.28,0,0,0-15,30.33c2.92,10.9,12.39,18.24,24.25,18.81,8.61.4,14.55,7.42,12.88,15.22a12.76,12.76,0,0,1-13.26,10.31c-6.33-.28-11.22-5.1-11.94-11.76-.53-4.94-2.85-7.42-6.79-7.25S237.07,297.47,237.2,303.36Z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Dividends',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
