import RouterComponent from '@/components/router/Router.vue';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/withdraws',
      name: 'Withdraws',
      component: () => import('@/pages/withdraws/Withdraws.vue'),
    },
  ],
};
