<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.001"
    height="21"
    viewBox="0 0 24.001 21"
  >
    <path
      class="a"
      d="M889.5,393.5H893a.5.5,0,0,1,.487.386l1.322,5.614H912.5a.5.5,0,0,1,.475.659l-3,9a.5.5,0,0,1-.475.341H897.161l.236,1H908a2,2,0,1,1-1.722,1h-7.555a2,2,0,1,1-2.329-.895L892.6,394.5h-3.1a.5.5,0,0,1,0-1Z"
      transform="translate(-889 -393.5)"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Checkouts',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
