<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g
      id="Group_4389"
      data-name="Group 4389"
      transform="translate(0.8 0.5)"
    >
      <path
        id="Path_10553"
        data-name="Path 10553"
        d="M75,231.847a.741.741,0,0,1,.739-.739h5.566c.407,0,.514-.245.239-.545l-1.754-1.913a.781.781,0,0,1,.024-1.067l.219-.219a.725.725,0,0,1,1.036.01l4.147,4.305a.784.784,0,0,1,0,1.069l-4.077,4.3a.718.718,0,0,1-1.031.015l-.219-.219a.792.792,0,0,1-.03-1.073l1.7-1.9c.271-.3.16-.551-.246-.551H75.739a.741.741,0,0,1-.739-.739Z"
        transform="translate(-71.822 -223.717)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="square"
        stroke-linejoin="round"
        stroke-width="1"
      ></path>
      <circle
        id="Ellipse_97"
        data-name="Ellipse 97"
        cx="8.5"
        cy="8.5"
        r="8.5"
        transform="translate(-0.3)"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="1"
      ></circle>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'ArrowRight',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
