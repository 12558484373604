type ActionType =
  | {
      text: string;
      handler: any;
    }[]
  | null;

function notify(
  that: any,
  type: string,
  response: string,
  actions: ActionType = null
) {
  that.$root.$refs.$notify.init(type, response, actions);
}

export default notify;
