import { v1 as uuid } from 'uuid';
import {
  IBaseCurrency,
  IExchangeStore,
  IExchangeStorePairRate,
  ITargetCurrency,
} from '@/models/interfaces/stores/IExchangeStore';
import { IBalanceItem } from '@/models/interfaces/balanceService/IBalanceService';
import {
  IExchangeHistoryData,
  IExchangePairRateData,
  IExchangePairsData,
  IExchangeTokenListData,
} from '@/models/interfaces/exchange/IExchange';

export default {
  state: {
    allowExchange: true,
    autoExchangeList: null,
    exchangePairs: null,
    exchangeSpendList: null,
    exchangeTokenList: null,
    exchangeHistoryList: null,
    baseCurrency: null,
    targetCurrency: null,
    isExchangePairRate: false,
    exchangePairRate: null,
    exchangeListMeta: {
      total: 0,
    },
  },
  getters: {
    exchangePairs: (state: IExchangeStore) => state.exchangePairs,
    autoExchangeList: (state: IExchangeStore) => state.autoExchangeList,
    isExchangePairRate: (state: IExchangeStore) => state.isExchangePairRate,
    exchangeSpendList: (state: IExchangeStore) => {
      if (!state.exchangeSpendList || !state.exchangeSpendList?.length)
        return null;
      return state.exchangeSpendList.map((item) => {
        item.name = item.asset ? item.asset.name : '';
        item.abbr = item.asset ? item.asset.symbol.replace(/\d+/g, '') : '';
        // TODO: Need check this point (String type)
        item.balance_amount = String(item.available) || '0.00';
        item.type = 'crypto';
        return item;
      });
    },
    exchangeTokenList: (state: IExchangeStore) => {
      if (!state.exchangeTokenList || !state.exchangeTokenList?.length)
        return null;

      return state.exchangeTokenList.map((item) => {
        item.icon = `${item.network.toLowerCase().replace('_test', '')}.svg`;
        item.abbr = item.symbol.replace(/\d+/g, '');
        // item.balance_amount = item.available || '0.00';
        item.type = 'crypto';
        return item;
      });
    },
    exchangeHistoryList: (state: IExchangeStore) => {
      if (!state.exchangeHistoryList || !state.exchangeHistoryList?.length)
        return null;
      return state.exchangeHistoryList.map((item) => {
        item.exchange_created_at = item.createdAt;
        item.asset_from = {
          name: item.fromAsset.name,
          symbol: item.fromAsset.displaySymbol || item.fromAsset.symbol,
          icon: null,
        };
        item.asset_to = {
          name: item.toAsset.name,
          symbol: item.toAsset.displaySymbol || item.toAsset.symbol,
          icon: null,
        };
        return item;
      });
    },
    baseCurrency: (state: IExchangeStore) => state.baseCurrency,
    targetCurrency: (state: IExchangeStore) => state.targetCurrency,
    exchangePairRate: (state: IExchangeStore) => state.exchangePairRate,
    exchangeListMeta: (state: IExchangeStore) => state.exchangeListMeta,
    allowExchange: (state: IExchangeStore) => state.allowExchange,
  },
  mutations: {
    SET_EXCHANGE_SPEND_LIST(state: IExchangeStore, data: IBalanceItem[]) {
      state.exchangeSpendList = data;
    },
    SET_EXCHANGE_TOKEN_LIST(
      state: IExchangeStore,
      data: IExchangeTokenListData[]
    ) {
      state.exchangeTokenList = data;
    },
    SET_BASE_CURRENCY(state: IExchangeStore, data: IBaseCurrency) {
      if (data) {
        data.uuid = uuid();
      }

      state.baseCurrency = data;
    },
    SET_TARGET_CURRENCY(state: IExchangeStore, data: ITargetCurrency) {
      if (data) {
        data.uuid = uuid();
      }

      state.targetCurrency = data;
    },
    SET_IS_EXCHANGE_PAIR_RATE(state: IExchangeStore, data: boolean) {
      state.isExchangePairRate = data;
    },
    SET_ALLOW_EXCHANGE(state: IExchangeStore, data: boolean) {
      state.allowExchange = data;
    },
    SET_EXCHANGE_PAIR_RATE(
      state: IExchangeStore,
      data: IExchangeStorePairRate
    ) {
      state.exchangePairRate = data;
    },
    SET_EXCHANGE_PAIRS(state: IExchangeStore, data: IExchangePairsData[]) {
      state.exchangePairs = data;
    },
    SET_EXCHANGE_HISTORY_LIST(
      state: IExchangeStore,
      data: IExchangeHistoryData[]
    ) {
      state.exchangeHistoryList = data;
    },
    SET_EXCHANGE_HISTORY_LIST_META(state: IExchangeStore, meta: number) {
      state.exchangeListMeta.total = Number(meta);
    },
    SET_AUTO_EXCHANGE_LIST(state: IExchangeStore, data: any) {
      state.autoExchangeList = data;
    },
    RESET(state: IExchangeStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        allowExchange: true,
        autoExchangeList: null,
        exchangePairs: [],
        exchangeSpendList: [],
        exchangeTokenList: [],
        exchangeHistoryList: [],
        baseCurrency: null as any,
        targetCurrency: null as any,
        isExchangePairRate: false,
        exchangePairRate: null as any,
        exchangeListMeta: {
          total: 0,
        },
      };
    },
  },
  actions: {
    setExchangeSpendList(context: any, data: IBalanceItem[]) {
      context.commit('SET_EXCHANGE_SPEND_LIST', data);
    },
    setExchangeTokenList(context: any, data: IExchangeTokenListData[]) {
      context.commit('SET_EXCHANGE_TOKEN_LIST', data);
    },
    setBaseCurrency(context: any, data: IBaseCurrency) {
      context.commit('SET_BASE_CURRENCY', data);
    },
    setTargetCurrency(context: any, data: ITargetCurrency) {
      context.commit('SET_TARGET_CURRENCY', data);
    },
    setIsExchangePairRate(context: any, data: IExchangeTokenListData) {
      context.commit('SET_IS_EXCHANGE_PAIR_RATE', data);
    },
    setExchangePairRate(context: any, data: IExchangeTokenListData) {
      context.commit('SET_EXCHANGE_PAIR_RATE', data);
    },
    setAllowExchange(context: any, data: boolean) {
      context.commit('SET_ALLOW_EXCHANGE', data);
    },
    setExchangePairs(context: any, data: IExchangePairRateData[]) {
      context.commit('SET_EXCHANGE_PAIRS', data);
    },
    setExchangeHistoryList(context: any, data: IExchangeHistoryData[]) {
      context.commit('SET_EXCHANGE_HISTORY_LIST', data);
    },
    setExchangeListMeta(context: any, meta: number) {
      context.commit('SET_EXCHANGE_HISTORY_LIST_META', meta);
    },
    setAutoExchangeList(context: any, data: any) {
      context.commit('SET_AUTO_EXCHANGE_LIST', data);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
