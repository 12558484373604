import Web3 from 'web3';
import MetaMaskOnboarding from '@metamask/onboarding';
import { IMetamaskStore } from '@/models/interfaces/stores/IMetamaskStore';

class MetamaskService {
  static initOnboarding() {
    const onboarding = new MetaMaskOnboarding();
    return onboarding;
  }

  static async initWeb3() {
    const web3 = new Web3(Web3.givenProvider);
    return web3;
  }

  static isMetaMaskInstalled() {
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  static isMetaMaskConnected(context: IMetamaskStore) {
    return context.accounts && context.accounts?.length > 0;
  }

  static async mint(
    smartContract: any,
    price: number,
    mintValue: number,
    fromAccount: any
  ) {
    const result = await smartContract.methods.mintItems(mintValue).send({
      value: price * mintValue,
      from: fromAccount,
    });

    return result;
  }
}

export default MetamaskService;
