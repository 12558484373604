<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="19.699"
    viewBox="0 0 22 19.699"
  >
    <g transform="translate(-241 -304.255)">
      <path
        class="a"
        d="M263.712,314.889a9.47,9.47,0,0,0-2.6-3.087.917.917,0,0,0-1.155,1.424,8.839,8.839,0,0,1,1.816,1.962c-1.1,1.332-4.612,5.12-8.978,5.12a8.006,8.006,0,0,1-1.236-.1.917.917,0,0,0-.283,1.812,9.951,9.951,0,0,0,1.519.119c6.221,0,10.643-6.083,10.828-6.342A.919.919,0,0,0,263.712,314.889Z"
        transform="translate(-0.792 -0.612)"
      ></path>
      <path
        class="a"
        d="M243.634,318.4a.916.916,0,1,0,1.159-1.42,8.7,8.7,0,0,1-1.773-1.924c1.1-1.33,4.613-5.12,8.981-5.12a7.994,7.994,0,0,1,.857.047.916.916,0,1,0,.2-1.821A9.824,9.824,0,0,0,252,308.1c-6.223,0-10.644,6.082-10.83,6.341a.919.919,0,0,0-.092.906A9.236,9.236,0,0,0,243.634,318.4Z"
        transform="translate(0 -0.32)"
      ></path>
      <path
        class="a"
        d="M253.483,318.79a3.667,3.667,0,0,0,3.5-4.764l-4.6,4.6A3.642,3.642,0,0,0,253.483,318.79Z"
        transform="translate(-0.949 -0.814)"
      ></path>
      <path
        class="a"
        d="M249.583,314.89a3.724,3.724,0,0,0,.089.795l4.372-4.372a3.723,3.723,0,0,0-.795-.089A3.671,3.671,0,0,0,249.583,314.89Z"
        transform="translate(-0.715 -0.581)"
      ></path>
      <path
        class="a"
        d="M262.284,304.524a.914.914,0,0,0-1.279-.015.225.225,0,0,0-.017.015L243.112,322.4a.912.912,0,0,0,0,1.3l0,0a.939.939,0,0,0,1.288,0l0,0,17.875-17.875A.92.92,0,0,0,262.284,304.524Z"
        transform="translate(-0.154)"
      ></path>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'EyeClose',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: #7f8fa6;
  }
</style>
