import { IInvoices, IOneInvoice } from '@/models/interfaces/invoice/IInvoice';
import { IInvoicesStore } from '@/models/interfaces/stores/IInvoicesStore';

export default {
  state: {
    invoicesList: [],
    invoicesMeta: {
      total: 0,
    },
    oneInvoice: {
      brand: {},
    },
  },
  getters: {
    invoicesList: (state: IInvoicesStore) =>
      state.invoicesList.map((item) => {
        item.name = item.customer_name;
        item.description = item.customer_email;
        item.status = item.invoice_status;
        item.amount_crypto = item.amount_to_btc;
        item.amount_usdt = item.amount_to_usdt;
        return item;
      }),
    invoicesMeta: (state: IInvoicesStore) => state.invoicesMeta,
    oneInvoice: (state: IInvoicesStore) => state.oneInvoice,
  },
  mutations: {
    SET_INVOICES_LIST(state: IInvoicesStore, invoices: IInvoices) {
      state.invoicesList = invoices.data;
      state.invoicesMeta.total = invoices.total;
    },
    SET_ONE_INVOICE(state: IInvoicesStore, invoice: IOneInvoice) {
      state.oneInvoice = invoice;
    },
    RESET(state: IInvoicesStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        invoicesList: [],
        invoicesMeta: {
          total: 0,
        },
        oneInvoice: {
          brand: {
            customer_name: '',
            customer_email: '',
            invoice_status: '',
            amount_to_btc: '',
            amount_to_usdt: '',
          },
        },
      };
    },
  },
  actions: {
    setInvoicesList(context: any, invoices: IInvoices) {
      context.commit('SET_INVOICES_LIST', invoices);
    },
    setOneInvoice(context: any, invoice: IOneInvoice) {
      context.commit('SET_ONE_INVOICE', invoice);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
