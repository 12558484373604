<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.001"
    height="16"
    viewBox="0 0 16.001 16"
  >
    <defs></defs>
    <g transform="translate(0 2.857)">
      <path
        class="a"
        d="M11.429,0H1.714A1.714,1.714,0,0,0,0,1.714v9.714a1.714,1.714,0,0,0,1.714,1.714h9.714a1.714,1.714,0,0,0,1.714-1.714V1.714A1.714,1.714,0,0,0,11.429,0ZM12,11.429a.569.569,0,0,1-.569.569H1.714a.569.569,0,0,1-.569-.569V1.714a.569.569,0,0,1,.569-.569h9.714A.569.569,0,0,1,12,1.714Z"
        transform="translate(0)"
      ></path>
    </g>
    <g transform="translate(2.286)">
      <g transform="translate(0)">
        <path
          class="a"
          d="M12,0H1.714A1.714,1.714,0,0,0,0,1.714a.571.571,0,0,0,1.143,0,.569.569,0,0,1,.569-.569H12a.569.569,0,0,1,.569.569V12A.569.569,0,0,1,12,12.57a.571.571,0,0,0,0,1.143A1.714,1.714,0,0,0,13.715,12V1.714A1.714,1.714,0,0,0,12,0Z"
          transform="translate(0)"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Copy',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
