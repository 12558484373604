import RouterComponent from '@/components/router/Router.vue';
import auth from '@/middleware/auth';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/operations',
      name: 'Operations',
      component: () => import('@/pages/payments/Payments.vue'),
      meta: {
        middleware: [auth],
      },
    },
  ],
};
