<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="24"
    viewBox="0 0 18 24"
  >
    <path
      class="a"
      d="M117.854,167.146l-6-6A.505.505,0,0,0,111.5,161h-11a.5.5,0,0,0-.5.5v23a.5.5,0,0,0,.5.5h17a.5.5,0,0,0,.5-.5v-17A.5.5,0,0,0,117.854,167.146ZM104.5,168H109a.5.5,0,0,1,0,1h-4.5a.5.5,0,0,1,0-1Zm9,13h-9a.5.5,0,0,1,0-1h9a.5.5,0,0,1,0,1Zm0-3h-9a.5.5,0,0,1,0-1h9a.5.5,0,0,1,0,1Zm0-3h-9a.5.5,0,0,1,0-1h9a.5.5,0,0,1,0,1Zm0-3h-9a.5.5,0,0,1,0-1h9a.5.5,0,0,1,0,1Zm-2.5-4v-6l6,6Z"
      transform="translate(-100 -161)"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Invoices',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
