import RouterComponent from '@/components/router/Router.vue';
import auth from '@/middleware/auth';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/payouts/:id',
      name: 'Payout_Details',
      parent: 'Payouts',
      component: () => import('@/pages/payout-details/PayoutDetails.vue'),
      meta: {
        middleware: [auth],
      },
    },
  ],
};
