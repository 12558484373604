import StorageHelper from '@/helpers/localStorageHelper';
import ApiBase from '@/api/baseApi';

interface IAuthParams {
  next: any;
}

export default function auth({ next }: IAuthParams) {
  if (!StorageHelper.get(ApiBase.authTokenKey())) {
    return next({
      name: 'SignIn',
    });
  }

  return next();
}
