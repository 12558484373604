<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(-745 -1119)">
      <rect
        class="a"
        width="11"
        height="11"
        rx="0.5"
        transform="translate(745 1119)"
      ></rect>
      <rect
        class="a"
        width="11"
        height="11"
        rx="0.5"
        transform="translate(758 1119)"
      ></rect>
      <rect
        class="a"
        width="11"
        height="11"
        rx="0.5"
        transform="translate(745 1132)"
      ></rect>
      <rect
        class="a"
        width="11"
        height="11"
        rx="0.5"
        transform="translate(758 1132)"
      ></rect>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Dashboard',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
