import { IPaymentData } from '@/models/interfaces/payments/IPayments';
import { IPaymentsStore } from '@/models/interfaces/stores/IPaymentsStore';

export default {
  state: {
    orderList: null,
    paymentList: null,
    paymentListMeta: {
      total: 0,
    },
    orderListMeta: {
      total: 0,
    },
    coinNetworks: [],
    reportState: [],
    reportType: null,
    reportLoaded: false,
    reportMessage: null,
    reportPeriod: null,
    terminalError: false,
    terminalEmail: null,
  },
  getters: {
    reportMessage: (state: IPaymentsStore) => state.reportMessage,
    terminalEmail: (state: IPaymentsStore) => state.terminalEmail,
    terminalError: (state: IPaymentsStore) => state.terminalError,
    reportType: (state: IPaymentsStore) => state.reportType,
    paymentList: (state: IPaymentsStore) => {
      if (!state.paymentList) return null;
      return state.paymentList.map((item) => {
        item.created_at = item.createdAt;
        item.asset_name = item.asset.name;
        item.asset_symbol = item.asset.symbol;
        item.network = item.asset.network;
        return item;
      });
    },
    orderList: (state: IPaymentsStore) => state.orderList,
    orderListMeta: (state: IPaymentsStore) => state.orderListMeta,
    paymentListMeta: (state: IPaymentsStore) => state.paymentListMeta,
    coinNetworks: (state: IPaymentsStore) => state.coinNetworks,
    reportState: (state: IPaymentsStore) => state.reportState,
    reportLoaded: (state: IPaymentsStore) => state.reportLoaded,
    reportPeriod: (state: IPaymentsStore) => state.reportPeriod,
  },
  mutations: {
    SET_TERMINAL_EMAIL(state: IPaymentsStore, data: string) {
      state.terminalEmail = data;
    },
    SET_TERMINAL_ERROR(state: IPaymentsStore, data: boolean) {
      state.terminalError = data;
    },
    SET_REPORT_LOADED(state: IPaymentsStore, data: boolean) {
      state.reportLoaded = data;
    },
    SET_REPORT_MESSAGE(state: IPaymentsStore, data: string) {
      state.reportMessage = data;
    },
    SET_REPORT_STATE(state: IPaymentsStore, paymentList: IPaymentData[]) {
      state.reportState = paymentList;
    },
    SET_REPORT_TYPE(state: IPaymentsStore, reportType: 'Operation' | 'Fin') {
      state.reportType = reportType;
    },
    SET_PAYMENT_LIST(state: IPaymentsStore, paymentList: IPaymentData[]) {
      state.paymentList = paymentList;
    },
    SET_ORDER_LIST(state: IPaymentsStore, orderList: any) {
      state.orderList = orderList;
    },
    SET_PAYMENT_LIST_META(state: IPaymentsStore, meta: number) {
      state.paymentListMeta.total = meta;
    },
    SET_ORDER_LIST_META(state: IPaymentsStore, meta: number) {
      state.orderListMeta.total = meta;
    },
    SET_COIN_NETWORKS(state: IPaymentsStore, coinNetworks: any) {
      state.coinNetworks = coinNetworks;
    },
    SET_REPORT_PERIOD(state: IPaymentsStore, period: string) {
      state.reportPeriod = period;
    },
    RESET(state: IPaymentsStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        paymentList: [],
        paymentListMeta: {
          total: 0,
        },
        orderList: [],
        orderListMeta: {
          total: 0,
        },
        coinNetworks: [],
        reportState: [],
        reportType: null,
        reportLoaded: false,
        reportMessage: null,
        reportPeriod: null,
        terminalError: false,
        terminalEmail: null,
      };
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    setReportMessage(context: any, data: string) {
      context.commit('SET_REPORT_MESSAGE', data);
    },
    setTerminalEmail(context: any, data: string) {
      context.commit('SET_TERMINAL_EMAIL', data);
    },
    setTerminalError(context: any, data: boolean) {
      context.commit('SET_TERMINAL_ERROR', data);
    },
    setReportType(context: any, reportType: 'Operation' | 'Fin') {
      context.commit('SET_REPORT_TYPE', reportType);
    },
    setReportLoaded(context: any, data: any) {
      context.commit('SET_REPORT_LOADED', data);
    },
    setReportPeriod(context: any, period: string) {
      context.commit('SET_REPORT_PERIOD', period);
    },
    setReportState(context: any, data: IPaymentData) {
      context.commit('SET_REPORT_STATE', data);
    },
    setPaymentList(context: any, paymentList: IPaymentData) {
      context.commit('SET_PAYMENT_LIST', paymentList);
    },
    setOrderList(context: any, orderList: any) {
      context.commit('SET_ORDER_LIST', orderList);
    },
    setPaymentListMeta(context: any, meta: number) {
      context.commit('SET_PAYMENT_LIST_META', meta);
    },
    setOrderListMeta(context: any, meta: number) {
      context.commit('SET_ORDER_LIST_META', meta);
    },
    setCoinNetworks(context: any, coinNetworks: any) {
      const coinNetworksFormatted: any = [];
      const coinNetworkKeys = Object.keys(coinNetworks);
      coinNetworkKeys.forEach((key) => {
        coinNetworksFormatted.push({
          key,
          ...coinNetworks[key],
        });
      });
      context.commit('SET_COIN_NETWORKS', coinNetworksFormatted);
    },
  },
};
