<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="14"
    viewBox="0 0 24 14"
  >
    <g
      id="Group_6858"
      data-name="Group 6858"
      transform="translate(-985.001 -2756)"
    >
      <path
        id="Path_12281"
        class="a"
        data-name="Path 12281"
        d="M999.5,2760H988.414l2.293-2.293a1,1,0,0,0-1.414-1.414l-4,4a1,1,0,0,0,0,1.415l4,4a1,1,0,0,0,1.414-1.414L988.414,2762H999.5a1,1,0,0,0,0-2Z"
      ></path>
      <path
        id="Path_12282"
        class="a"
        data-name="Path 12282"
        d="M994.5,2764h11.086l-2.293-2.293a1,1,0,0,1,1.414-1.414l4,4a1,1,0,0,1,0,1.415l-4,4a1,1,0,0,1-1.414-1.414l2.293-2.293H994.5a1,1,0,0,1,0-2Z"
      ></path>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'ExchangeIcon',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
