/* eslint-disable import/no-import-module-exports */
import axios from 'axios';
import moment from 'moment';

export default class SlackNotifier {
  public static sendToSlack(message: string) {
    const slackToken = process.env.VUE_APP_SLACK_TOKEN;
    const channel = process.env.VUE_APP_CHANNEL_ID;
    const apiUrl = process.env.VUE_APP_SLACK_WEBHOOK;
    const headers = {};

    if (
      !slackToken ||
      !channel ||
      !apiUrl ||
      process.env.VUE_APP_ENVIRONMENT === 'development'
    ) {
      return;
    }

    const backedMessage = `${window.location.href} (${
      process.env.VUE_APP_ENVIRONMENT
    } stage) \n${moment().format('DD-MM-YYYY HH:mm:ss')}\n\n${message}`;

    // Set up the request payload
    const payload = {
      channel,
      text: backedMessage,
    };

    // Send the API request
    axios.post(apiUrl, JSON.stringify(payload), { headers });
  }
}
