import { IStatementStore } from '@/models/interfaces/stores/IStatementStore';

export default {
  state: {
    statementList: [
      {
        text: 'Daily',
        value: 'daily',
      },
      {
        text: 'Weekly',
        value: 'weekly',
      },
      {
        text: 'Monthly',
        value: 'monthly',
      },
    ],
    selectedStatementList: [],
  },
  getters: {
    statementList: (state: IStatementStore) =>
      state.statementList.map((item) => {
        item.active = state.selectedStatementList.includes(item.value);
        return item;
      }),
  },
  mutations: {
    SET_SELECTED_STATEMENT_LIST(state: IStatementStore, data: string[]) {
      state.selectedStatementList = data;
    },
    RESET(state: IStatementStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        statementList: [
          {
            text: 'Hourly',
            value: 'hourly',
          },
          {
            text: 'Daily',
            value: 'daily',
          },
          {
            text: 'Weekly',
            value: 'weekly',
          },
          {
            text: 'Monthly',
            value: 'monthly',
          },
        ],
        selectedStatementList: [],
      };
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    setSelectedStatementList(context: any, data: string[]) {
      context.commit('SET_SELECTED_STATEMENT_LIST', data);
    },
  },
};
