<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.999"
    height="22.874"
    viewBox="0 0 23.999 22.874"
  >
    <g transform="translate(-841 -1542.5)">
      <rect
        class="a"
        width="4"
        height="9"
        rx="0.5"
        transform="translate(841 1555.5)"
      ></rect>
      <path
        class="a"
        d="M864.853,1559.646a3.245,3.245,0,0,0-3.511-1.121l-3.008,1A2.419,2.419,0,0,1,856,1562.5h-5a.5.5,0,0,1,0-1h5a1.366,1.366,0,0,0,1.375-1.5,1.382,1.382,0,0,0-1.375-1.5h-3a.5.5,0,0,1-.353-.146,5.782,5.782,0,0,0-4.147-1.854h-2a.5.5,0,0,0-.5.5v5.5a.5.5,0,0,0,.342.474c1.56.52,3.281.964,4.261,1.32a12.833,12.833,0,0,0,3.968,1.08c1.947,0,3.387-1.545,10.153-4.927A.5.5,0,0,0,864.853,1559.646Z"
      ></path>
      <path
        class="a"
        d="M858,1549.5a3.5,3.5,0,1,0-3.5-3.5A3.5,3.5,0,0,0,858,1549.5Zm-.5-4.5a.5.5,0,0,1,1,0v2a.5.5,0,0,1-1,0Z"
      ></path>
      <path
        class="a"
        d="M853.5,1556.5a3.5,3.5,0,1,0-3.5-3.5A3.5,3.5,0,0,0,853.5,1556.5Zm-.5-4.5a.5.5,0,0,1,1,0v2a.5.5,0,0,1-1,0Z"
      ></path>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Withdraws',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
