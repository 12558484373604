import RouterComponent from '@/components/router/Router.vue';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/support-email-confirmation',
      name: 'SupportEmailConfirmation',
      component: () =>
        import(
          '@/pages/support-email-confirmation/SupportEmailConfirmation.vue'
        ),
    },
  ],
};
