import RouterComponent from '@/components/router/Router.vue';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/order',
      name: 'OrderTerminal',
      component: () => import('@/pages/terminal/Terminal.vue'),
    },
  ],
};
