import RouterComponent from '@/components/router/Router.vue';
import auth from '@/middleware/auth';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/dedicated-addresses',
      name: 'Dedicated_Address',
      component: () => import('@/pages/dedicated-address/DedicatedAddress.vue'),
      meta: {
        middleware: [auth],
      },
    },
  ],
};
