import { IWithdrawStore } from '@/models/interfaces/stores/IWithdrawStore';
import { IWithdrawData } from '@/models/interfaces/withdraw/IWithdraw';

export default {
  state: {
    withdrawsList: [],
  },
  getters: {
    // TODO: Check if types what declared are completed
    withdrawsList: (state: IWithdrawStore) =>
      [...state.withdrawsList].map((item) => {
        item.name = item.network;
        item.created_at = item.createdAt;
        item.icon = `${item.network.toLowerCase().replace('_test', '')}.svg`;
        return item;
      }),
  },
  mutations: {
    RESET(state: IWithdrawStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        withdrawsList: [],
      };
    },
    SET_WITHDRAWS_LIST(state: IWithdrawStore, withdrawsList: IWithdrawData[]) {
      state.withdrawsList = withdrawsList;
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    setWithdrawsList(context: any, withdrawsList: IWithdrawData[]) {
      context.commit('SET_WITHDRAWS_LIST', withdrawsList);
    },
  },
};
