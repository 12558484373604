import Vue from 'vue';
import VueRouter from 'vue-router';
// import config from '@/config/config';
import StorageHelper from '@/helpers/localStorageHelper';
import ApiBase from '@/api/baseApi';
import UserAPI from '@/api/userApi';
import HttpHelper from '@/helpers/httpHelper';
import { RoleTypeAction } from '@/helpers/roleCheckHelper';
import Dashboard from './routes/dashboard';
import SignIn from './routes/signIn';
// import SignUp from './routes/signUp';
import ForgotPassword from './routes/forgot-password';
import ResetPassword from './routes/reset-password';
import EmailVerification from './routes/email-verification';
// import Settings from './routes/settings';
import Balances from './routes/balances';
import Withdraws from './routes/withdraws';
import Operations from './routes/operations';
import Checkouts from './routes/checkouts';
import Invoices from './routes/invoices';
import InvoiceSummary from './routes/invoice-summary';
import OrderSummary from './routes/order-summary';
import OrderTerminal from './routes/order-terminal';
import SupportEmailConfirmation from './routes/support-email-confirmation';
import ExchangePage from './routes/exchange-page';
import NotFound from './routes/not-found';
import Onboarding from './routes/onboarding';
import Team from './routes/team';
import Profile from './routes/profile';
// import Branding from './routes/branding';
// import Verification from './routes/verification';
import ActiveCurrency from './routes/active-currency';
import API from './routes/api';
import Statement from './routes/statement';
import Report from './routes/report';
import Terminal from './routes/terminal';
import Payouts from './routes/payouts';
import PayoutDetails from './routes/payout-details';
import Orders from './routes/orders';
import DedicatedAddress from './routes/dedicated-addresses';
import Dividends from './routes/dividends';
import DividendsSpreads from './routes/dividends-spreads';
// import WhitelistedDomain from './routes/whitelisted-domain';
// import CloseAccount from './routes/close-account';

Vue.use(VueRouter);

const followingPathLSKey = 'following_path_key';
const hiddenDevToolsLSKey = 'hidden_dev_tools';

const mainChildrenRoutesProd = [
  DedicatedAddress,
  Orders,
  Dashboard,
  Balances,
  Withdraws,
  Operations,
  Checkouts,
  Invoices,
  Team,
  Statement,
  ExchangePage,
  Onboarding,
  Profile,
  Payouts,
  PayoutDetails,
  ActiveCurrency,
  API,
  Report,
  Dividends,
  DividendsSpreads,
];

const mainChildrenRoutesDev = [
  DedicatedAddress,
  Orders,
  Dashboard,
  Balances,
  Withdraws,
  Operations,
  Checkouts,
  Invoices,
  Team,
  Statement,
  ExchangePage,
  Onboarding,
  Profile,
  Payouts,
  PayoutDetails,
  ActiveCurrency,
  API,
  Report,
  Dividends,
  DividendsSpreads,
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      component: () => import('@/components/main-container/MainContainer.vue'),
      children:
        process.env.VUE_APP_ENVIRONMENT === 'development'
          ? mainChildrenRoutesDev
          : mainChildrenRoutesProd,
    },
    {
      path: '/',
      component: () => import('@/components/auth-container/AuthContainer.vue'),
      children: [
        SignIn,
        // SignUp,
        ForgotPassword,
        ResetPassword,
        EmailVerification,
        SupportEmailConfirmation,
      ],
    },
    {
      path: '/',
      component: () =>
        import('@/components/payment-container/PaymentContainer.vue'),
      children: [InvoiceSummary, OrderSummary, Terminal, OrderTerminal],
    },
    {
      path: '*',
      component: () => import('@/components/main-container/MainContainer.vue'),
      children: [NotFound],
    },
  ],
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

router.beforeEach(async (to, from, next) => {
  if (to.path === '/hidden-dev-tools') {
    const hdtKey = StorageHelper.get(hiddenDevToolsLSKey);

    if (!hdtKey || hdtKey !== 1) {
      StorageHelper.set(hiddenDevToolsLSKey, 1);
    } else {
      StorageHelper.remove(hiddenDevToolsLSKey);
    }

    return next({ path: '/' });
  }

  const matchedAvailableRoute = [
    'OrderSummary',
    'InvoiceSummary',
    'Terminal',
    'OrderTerminal',
  ].some((name) => to.name?.includes(name));

  if (matchedAvailableRoute) return next();

  const matchedRoute = [
    'SignIn',
    'SignUp',
    'ForgotPassword',
    'ResetPassword',
    'EmailVerification',
    'SupportEmailConfirmation',
  ].some((name) => to.name?.includes(name));

  if (!matchedRoute && !StorageHelper.get(ApiBase.authTokenKey())) {
    if (to.name === 'Not found') {
      StorageHelper.remove(followingPathLSKey);
    } else {
      StorageHelper.set(followingPathLSKey, to.fullPath);
    }

    return next({ name: 'SignIn' });
  }

  if (!matchedRoute && StorageHelper.get(ApiBase.authTokenKey())) {
    const httpHelperContext = new HttpHelper();
    if (!router.app.$store.getters.userData.project_roles) {
      await UserAPI.getProfile(httpHelperContext);
    }
    if (
      router.app.checkAccess(to.name!, RoleTypeAction.READ) &&
      router.app.checkMerchant(to.name!)
    ) {
      const followingPath = StorageHelper.get(followingPathLSKey);

      if (followingPath && to.name !== 'Not found') {
        StorageHelper.remove(followingPathLSKey);
        return next({ path: followingPath });
      }

      if (to.name === 'Not found') {
        return next({ name: 'Dashboard' });
      }

      return next();
    }

    const role = router.app.$store.getters.userData.roles[0];

    if (
      [
        'ROLE_DIVIDEND_ADMIN',
        'ROLE_SUPPORT_MANAGER',
        'ROLE_SUPPORT_LEAD',
      ].includes(role)
    ) {
      return next({ name: 'Operations' });
    }

    return next({ name: 'Dashboard' });
  }

  return next();
});

export default router;
