<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="64.000000pt"
    height="64.000000pt"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
      class="a"
    >
      <path
        d="M140 405 c0 -168 1 -175 20 -175 19 0 20 7 20 155 l0 155 180 0 180
0 0 -201 c0 -199 -6 -239 -35 -239 -20 0 -38 37 -32 66 l4 24 -208 0 -209 0 0
-30 c0 -37 23 -85 45 -94 22 -8 408 -8 430 0 39 15 45 53 45 289 l0 225 -220
0 -220 0 0 -175z"
      ></path>
      <path
        d="M224 476 c-10 -26 4 -48 28 -44 33 4 33 52 0 56 -13 2 -25 -3 -28
-12z"
      ></path>
      <path
        d="M320 460 c0 -19 7 -20 90 -20 83 0 90 1 90 20 0 19 -7 20 -90 20 -83
0 -90 -1 -90 -20z"
      ></path>
      <path
        d="M224 386 c-10 -26 4 -48 28 -44 33 4 33 52 0 56 -13 2 -25 -3 -28
-12z"
      ></path>
      <path
        d="M320 370 c0 -19 7 -20 90 -20 83 0 90 1 90 20 0 19 -7 20 -90 20 -83
0 -90 -1 -90 -20z"
      ></path>
      <path
        d="M224 296 c-10 -26 4 -48 28 -44 17 2 23 10 23 28 0 18 -6 26 -23 28
-13 2 -25 -3 -28 -12z"
      ></path>
      <path
        d="M320 280 c0 -19 7 -20 90 -20 83 0 90 1 90 20 0 19 -7 20 -90 20 -83
0 -90 -1 -90 -20z"
      ></path>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Orders',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
