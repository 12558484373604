import RouterComponent from '@/components/router/Router.vue';
import auth from '@/middleware/auth';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/active-currency',
      name: 'Active_Currency',
      component: () => import('@/pages/active-currency/ActiveCurrency.vue'),
      meta: {
        middleware: [auth],
      },
    },
  ],
};
