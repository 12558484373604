import RouterComponent from '@/components/router/Router.vue';
import auth from '@/middleware/auth';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/payouts',
      name: 'Payouts',
      component: () => import('@/pages/payouts/Payouts.vue'),
      meta: {
        middleware: [auth],
      },
    },
  ],
};
