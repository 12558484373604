import {
  IBalanceItem,
  IWalletList,
} from '@/models/interfaces/balanceService/IBalanceService';
import { IBalanceStore } from '@/models/interfaces/stores/IBalanceStore';

export default {
  state: {
    balanceList: null,
    balanceListMeta: {
      total: 0,
    },
    walletList: [],
    networkFee: 0,
  },
  getters: {
    balanceList: (state: IBalanceStore) => {
      if (!state.balanceList) return null;
      return state.balanceList.map((item: IBalanceItem) => {
        item.icon =
          typeof item.network === 'string'
            ? `${item.network.toLowerCase().replace('_test', '')}.svg`
            : null;
        item.name = item.asset.name;
        item.abbr = item.asset.symbol;
        // TODO: Need to check this point (String type)
        item.balance_amount = String(item.available);
        return item;
      });
    },
    balanceListMeta: (state: IBalanceStore) => state.balanceListMeta,
    walletList: (state: IBalanceStore) =>
      state.walletList.map((wallet: IWalletList) => {
        wallet.text = wallet.address || wallet.wallet;
        wallet.value = wallet.address || wallet.wallet;
        return wallet;
      }),
    networkFee: (state: IBalanceStore) => state.networkFee,
  },
  mutations: {
    SET_BALANCES(state: IBalanceStore, balances: IBalanceItem[]) {
      state.balanceList = balances;
    },
    SET_BALANCE_LIST_META(state: IBalanceStore, meta: number) {
      state.balanceListMeta.total = meta;
    },
    SET_WALLET_LIST(state: IBalanceStore, data: IWalletList[]) {
      state.walletList = data;
    },
    SET_NETWORK_FEE(state: IBalanceStore, fee: number) {
      state.networkFee = fee;
    },
    RESET(state: IBalanceStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        balanceList: [],
        balanceListMeta: {
          total: 0,
        },
        walletList: [],
        networkFee: 0,
      };
    },
  },
  actions: {
    setBalances(context: any, balances: IBalanceItem[]) {
      context.commit('SET_BALANCES', balances);
    },
    setWalletList(context: any, data: IWalletList[]) {
      context.commit('SET_WALLET_LIST', data);
    },
    setNetworkFee(context: any, fee: number) {
      context.commit('SET_NETWORK_FEE', fee);
    },
    setBalanceListMeta(context: any, meta: number) {
      context.commit('SET_BALANCE_LIST_META', meta);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
