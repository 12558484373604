import Vue from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';

// import plugins
import './plugins/axios';

import vueMoment from 'vue-moment';
import { AsyncFilterMixin } from '@tygr/vue-async-filter';
import VueMeta from 'vue-meta';
import CustomSelector from '@/components/custom-selector/CustomSelector.vue';
import Hotjar from '@hotjar/browser';
import store from './store';
import router from './router';
import App from './App.vue';

// import css
// eslint-disable-next-line import/extensions
import '@/assets/scss/app.scss';
import 'vuetify/dist/vuetify.min.css';
// requires
import '@/plugins/vueMixin';
import '@/helpers/rulesHelper';
import vuetify from './plugins/vuetify';

// Slack Notifier
// import SlackNotifier from './utils/slack-notifier';
// Vue.prototype.$toSlack = SlackNotifier.sendToSlack;
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars

if (process.env.VUE_APP_ENABLE_HOTJAR) {
  const siteId = 3751535;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
Vue.use(vueMoment);
Vue.use(VueAxios, axios);
Vue.mixin(AsyncFilterMixin);
Vue.config.productionTip = false;
Vue.component('CustomSelector', CustomSelector);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
