import { IErrorFieldKey } from '@/models/interfaces/error/IError';
import { fields } from './constants';
import notify from './notifyHelper';

type ErrorHandlerOptions = {
  showNotification?: boolean;
  notificationMessage?: string | null;
  throwError?: boolean;
  warning?: boolean;
};

export function errorHandler(
  self: any,
  error: Error,
  options: ErrorHandlerOptions = {
    showNotification: false,
    notificationMessage: null,
    throwError: true,
    warning: false,
  }
) {
  const { showNotification, notificationMessage, throwError, warning } =
    options;

  const errorType = warning ? 'warn' : 'error';

  if (showNotification) {
    if (notificationMessage) {
      notify(self, errorType, notificationMessage);
    } else if (error?.message?.length) {
      notify(self, errorType, error.message);
    } else if ((error as any)?.msg) {
      notify(self, errorType, (error as any).msg);
    }
  }

  if (self.errors && error?.message?.length) self.errors = error.message;

  if (
    throwError &&
    error?.message &&
    process.env.VUE_APP_ENVIRONMENT === 'development'
  )
    throw new Error(error.message);
}

export function expandedErrorHandler(self: any, error: any) {
  if (typeof error?.message === 'object') {
    const fieldName: IErrorFieldKey = Object.keys(
      error.message
    )[0] as IErrorFieldKey;

    errorHandler(self, error, {
      showNotification: !!error?.message[fieldName],
      notificationMessage:
        error?.message[fieldName] &&
        `${fields[fieldName] || fieldName} - ${error.message[
          fieldName
        ].toLowerCase()}`,
    });
  } else {
    errorHandler(self, error, {
      showNotification: true,
    });
  }
}
