import RouterComponent from '@/components/router/Router.vue';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/email-verification',
      name: 'EmailVerification',
      component: () =>
        import('@/pages/email-verification/EmailVerification.vue'),
    },
  ],
};
