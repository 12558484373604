const metaInfo = [
  {
    chainId: 1,
    rpcUrls: [
      'https://api.mycryptoapi.com/eth',
      'https://cloudflare-eth.com',
      'https://ethereum-rpc.publicnode.com',
      'wss://ethereum-rpc.publicnode.com',
      'https://mainnet.gateway.tenderly.co',
      'wss://mainnet.gateway.tenderly.co',
      'https://rpc.blocknative.com/boost',
      'https://rpc.flashbots.net',
      'https://rpc.flashbots.net/fast',
      'https://rpc.mevblocker.io',
      'https://rpc.mevblocker.io/fast',
      'https://rpc.mevblocker.io/noreverts',
      'https://rpc.mevblocker.io/fullprivacy',
    ],
    chainName: 'Ethereum Mainnet',
    nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
    blockExplorerUrls: [
      'https://etherscan.io',
      'https://eth.blockscout.com',
      'https://ethereum.dex.guru',
    ],
  },
  {
    chainId: 5,
    rpcUrls: [
      'https://rpc.goerli.mudit.blog/',
      'https://ethereum-goerli-rpc.publicnode.com',
      'wss://ethereum-goerli-rpc.publicnode.com',
      'https://goerli.gateway.tenderly.co',
      'wss://goerli.gateway.tenderly.co',
    ],
    chainName: 'Goerli',
    nativeCurrency: { name: 'Goerli Ether', decimals: 18, symbol: 'ETH' },
    blockExplorerUrls: [
      'https://goerli.etherscan.io',
      'https://eth-goerli.blockscout.com',
    ],
  },
  {
    chainId: 97,
    rpcUrls: [
      'https://data-seed-prebsc-1-s1.bnbchain.org:8545',
      'https://data-seed-prebsc-2-s1.bnbchain.org:8545',
      'https://data-seed-prebsc-1-s2.bnbchain.org:8545',
      'https://data-seed-prebsc-2-s2.bnbchain.org:8545',
      'https://data-seed-prebsc-1-s3.bnbchain.org:8545',
      'https://data-seed-prebsc-2-s3.bnbchain.org:8545',
      'https://bsc-testnet-rpc.publicnode.com',
      'wss://bsc-testnet-rpc.publicnode.com',
    ],
    chainName: 'BNB Smart Chain Testnet',
    nativeCurrency: {
      name: 'BNB Chain Native Token',
      decimals: 18,
      symbol: 'tBSC',
    },
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  {
    chainId: 56,
    rpcUrls: [
      'https://bsc-dataseed1.bnbchain.org',
      'https://bsc-dataseed2.bnbchain.org',
      'https://bsc-dataseed3.bnbchain.org',
      'https://bsc-dataseed4.bnbchain.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed2.defibit.io',
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed4.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed2.ninicoin.io',
      'https://bsc-dataseed3.ninicoin.io',
      'https://bsc-dataseed4.ninicoin.io',
      'https://bsc-rpc.publicnode.com',
      'wss://bsc-rpc.publicnode.com',
      'wss://bsc-ws-node.nariox.org',
    ],
    chainName: 'BSC',
    nativeCurrency: {
      name: 'BNB Chain Native Token',
      decimals: 18,
      symbol: 'BNB',
    },
    blockExplorerUrls: ['https://bscscan.com', 'https://bnb.dex.guru'],
  },
];

const getMetaByChainID = (chainID: number) =>
  metaInfo.filter((e) => e.chainId === chainID);

export default getMetaByChainID;
