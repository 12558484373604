import currenciesIcons from '@/helpers/currenciesIcons';

export default {
  name: 'List',
  props: [
    'items',
    'type',
    'selectorType',
    'selectItem',
    'selectedItem',
    'bullets',
    'afterMerchantListText',
    'still',
  ],
  computed: {
    icons: () => currenciesIcons,
  },
  methods: {
    isMerchant(item: any) {
      return item.parentLabel;
    },
    setParentBulletClass(item: any) {
      const merchantName = item.parentLabel;
      const foundChildActive = (this as any).items.find(
        (i: any) => i.value === (this as any).selectedItem
      );

      if (
        merchantName &&
        foundChildActive &&
        foundChildActive.childLabel === merchantName
      ) {
        return 'active-parrent';
      }
    },
    setChildBulletClass(item: any) {
      const self = this as any;
      if (item.parentLabel && item.parentLabel === self.selectedItem) {
        return 'active-parent-only';
      }

      if (item.value === self.selectedItem) {
        return 'active-child';
      }
    },
  },
};
