import {
  ISettingsUserData,
  IUserActiveCryptoData,
} from '@/models/interfaces/settings/ISettings';
import { IUserStore } from '@/models/interfaces/stores/IUserStore';

export default {
  state: {
    userData: {},
    userActiveCryptoList: [],
    isAggregateData: false,
    inqueryConfirmationWith2FA: false,
  },
  getters: {
    isAggregateData: (state: IUserStore) => state.isAggregateData,
    userData: (state: IUserStore) => state.userData,
    inqueryConfirmationWith2FA: (state: IUserStore) =>
      state.inqueryConfirmationWith2FA,
    userActiveCryptoList: (state: IUserStore) =>
      state.userActiveCryptoList.map((item) => {
        item.logo = `${item.network.toLowerCase()}.svg`;
        return item;
      }),
  },
  mutations: {
    SET_IS_AGGREGATE_DATA(state: IUserStore, value: boolean) {
      state.isAggregateData = value;
    },
    SET_USER_DATA_LIST(state: IUserStore, user: ISettingsUserData) {
      state.userData = user;
    },
    MODIFY_USER_DATA_LIST(state: IUserStore, data: ISettingsUserData) {
      for (const key in data) {
        if ({}.hasOwnProperty.call(data, key)) {
          (state.userData as any)[key] = data[key as keyof ISettingsUserData];
        }
      }
    },
    CLEAR_USER_DATA_LIST(state: IUserStore) {
      state.userData = {} as ISettingsUserData;
    },
    SET_USER_ACTIVE_CRYPTO_LIST(
      state: IUserStore,
      data: IUserActiveCryptoData[]
    ) {
      state.userActiveCryptoList = data;
    },
    SET_BACKUP_CODES(state: IUserStore, codes: string[]) {
      state.userData.backup_codes = codes;
    },
    SET_CONFIRMATION_WITH_2FA(state: IUserStore, value: boolean) {
      state.inqueryConfirmationWith2FA = value;
    },
    RESET(state: IUserStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        userData: {} as ISettingsUserData,
        userActiveCryptoList: [],
        isAggregateData: false,
        inqueryConfirmationWith2FA: false,
      };
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    inqueryConfirmationWith2FA(context: any, value: boolean) {
      context.commit('SET_CONFIRMATION_WITH_2FA', value);
    },
    setIsAggregateData(context: any, value: boolean) {
      context.commit('SET_IS_AGGREGATE_DATA', value);
    },
    setUserDataList(context: any, user: ISettingsUserData) {
      context.commit('SET_USER_DATA_LIST', user);
    },
    clearUserDataList(context: any, user: ISettingsUserData) {
      context.commit('CLEAR_USER_DATA_LIST', user);
    },
    modifyUserDataList(context: any, data: ISettingsUserData) {
      context.commit('MODIFY_USER_DATA_LIST', data);
    },
    setUserActiveCryptoList(context: any, data: IUserActiveCryptoData[]) {
      context.commit('SET_USER_ACTIVE_CRYPTO_LIST', data);
    },
    setBackupCodes(context: any, codes: string[]) {
      context.commit('SET_BACKUP_CODES', codes);
    },
  },
};
