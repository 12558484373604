import RouterComponent from '@/components/router/Router.vue';
import auth from '@/middleware/auth';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/exchange',
      name: 'Exchange',
      component: () => import('@/pages/exchange-page/ExchangePage.vue'),
      meta: {
        middleware: [auth],
      },
    },
  ],
};
