import axios from 'axios';
import AuthAPI from '@/api/authApi';
import ApiBase from '@/api/baseApi';
import StorageHelper from './localStorageHelper';
import notify from './notifyHelper';

const refreshingTokenProcessKey = 'refreshing_token_process';
type Methods = 'post' | 'get' | 'put' | 'delete' | 'patch';
export default class HttpHelper {
  private checkTokenRefreshingProcess() {
    let refreshingTokenProcess: any = null;
    let attempts = 100;

    return new Promise((resolve, reject) => {
      refreshingTokenProcess = window.setInterval(async () => {
        attempts -= 1;
        if (attempts <= 0) {
          if (refreshingTokenProcess)
            window.clearTimeout(refreshingTokenProcess);

          reject(new Error('Something wrong with refreshing the token'));
        }
        const isRefreshingTokenProcess = StorageHelper.get(
          refreshingTokenProcessKey
        );

        if (!isRefreshingTokenProcess) {
          // Clear refreshing token interval
          if (refreshingTokenProcess)
            window.clearTimeout(refreshingTokenProcess);
          // Remove refresh token process marker from storage
          StorageHelper.remove(refreshingTokenProcessKey);
          resolve(this);
        }
      }, 100);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private checkToken() {
    const authTokenTimeFromLS = StorageHelper.get(AuthAPI.authTokenTimeKey());
    let tokenTimeout: any = null;

    return new Promise((resolve, reject) => {
      if (authTokenTimeFromLS) {
        if (authTokenTimeFromLS <= new Date().getTime()) {
          // Set `refreshing_token_process` field local storage as true
          // To prevent the token from refreshing multiple times if there is more than 1 request per page
          StorageHelper.set(refreshingTokenProcessKey, true);

          // Clear token timeout
          if (tokenTimeout) window.clearTimeout(tokenTimeout);
          tokenTimeout = window.setTimeout(() => {
            axios({
              method: 'post',
              url: `${ApiBase.baseUrl()}/token`,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...ApiBase.authHeaders(),
              },
            })
              .then((response) => {
                AuthAPI.setAuthToken(response.data.token).then(() => {
                  resolve(true);
                });
              })
              .catch((error: Error) => {
                if (process.env.VUE_APP_ENVIRONMENT === 'development')
                  console.error(error);
                ApiBase.unauthoriseUser();
                reject(error);
              })
              .finally(() => {
                StorageHelper.remove(refreshingTokenProcessKey);
              });
          }, 1000);
        } else {
          StorageHelper.remove(refreshingTokenProcessKey);
          resolve(true);
        }

        // resolve(true);
      }
    });
  }

  public async http(method: Methods, url: string, params: any, header: any) {
    if (header && header.Authorization) {
      await this.checkTokenRefreshingProcess()
        .catch((err: any) => {
          throw new Error(err);
        })
        .then(() =>
          this.checkToken().catch((err: any) => {
            throw new Error(err);
          })
        )
        .then(() => {
          header = {
            ...header,
            ...ApiBase.authHeaders(),
          };
        });
    }

    method = method.toLowerCase() as Methods;
    const config: any = {
      method,
      url,
    };

    if (method === 'get') {
      config.params = params;
      config.headers = Object.assign(header || {}, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });
    } else if (method === 'post' || method === 'delete' || method === 'patch') {
      const form = new FormData();
      for (const field in params) {
        if (!params[field]) continue;
        if (
          typeof params[field] === 'object' &&
          !(params[field] instanceof File)
        ) {
          Object.keys(params[field]).forEach((key) => {
            form.append(`${field}[${key}]`, params[field][key]);
          });
        } else {
          form.append(field, params[field]);
        }
      }
      config.data = form;
      config.headers = Object.assign(header || {}, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });
    } else if (method === 'put') {
      config.data = params;
      config.headers = Object.assign(header || {}, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });
    }
    return new Promise((resolve, reject) =>
      // eslint-disable-next-line no-promise-executor-return
      axios(config)
        .then((response) => resolve(response.data))
        .catch((error: any) => {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                reject(error.response.data);
                break;
              case 413:
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({
                  message: 'The file is too large',
                });
                break;
              case 401:
                reject(error.response.data);
                ApiBase.unauthoriseUser();
                break;
              case 404:
                reject(error.response.data);
                break;
              case 500:
                // notify(this, 'error', error.response.data.message);
                reject(error);
                break;
              default:
                if (process.env.VUE_APP_ENVIRONMENT === 'development')
                  console.error('error', error.response);
                reject(error);
                break;
            }
          } else {
            if (process.env.VUE_APP_ENVIRONMENT === 'development') {
              console.error(error);
              notify(this, 'error', error);
            }
            reject(error);
          }
        })
    );
  }
}
