<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="12.833"
    viewBox="0 0 22 12.833"
  >
    <g transform="translate(-193 -308)">
      <path
        class="a"
        d="M193.1,314.7c.2.249,4.926,6.133,10.9,6.133s10.7-5.883,10.9-6.133a.457.457,0,0,0,0-.568c-.2-.251-4.925-6.133-10.9-6.133s-10.7,5.881-10.9,6.133A.457.457,0,0,0,193.1,314.7Zm10.9-4.409a4.125,4.125,0,1,1-4.125,4.125A4.13,4.13,0,0,1,204,310.292Z"
        transform="translate(0)"
      ></path>
      <circle
        class="a"
        cx="3.194"
        cy="3.194"
        r="3.194"
        transform="translate(200.807 311.222)"
      ></circle>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'Eye',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
