<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g
      id="Group_4390"
      data-name="Group 4390"
      transform="translate(0.5 0.5)"
    >
      <path
        id="Path_10553"
        data-name="Path 10553"
        d="M85.429,231.847a.741.741,0,0,0-.739-.739H79.123c-.407,0-.514-.245-.239-.545l1.754-1.913a.781.781,0,0,0-.024-1.067l-.219-.219a.725.725,0,0,0-1.036.01l-4.147,4.305a.784.784,0,0,0,0,1.069l4.077,4.3a.718.718,0,0,0,1.031.015l.219-.219a.792.792,0,0,0,.03-1.073l-1.7-1.9c-.271-.3-.16-.551.246-.551H84.69a.741.741,0,0,0,.739-.739Z"
        transform="translate(-71.385 -223.717)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="square"
        stroke-linejoin="round"
        stroke-width="1"
      ></path>
      <circle
        id="Ellipse_97"
        data-name="Ellipse 97"
        cx="8.5"
        cy="8.5"
        r="8.5"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="1"
      ></circle>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'ArrowLeft',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
