import { IDedicatedAddressStore } from '@/models/interfaces/stores/IDedicatedAddressStore';
import { IDedicatedAddressList } from '@/models/interfaces/dedicatedAddress/IDedicatedAddress';

export default {
  state: {
    dedicatedAddressList: [],
    dedicatedAddressTotal: 0,
  },
  getters: {
    dedicatedAddressList: (state: IDedicatedAddressStore) =>
      state.dedicatedAddressList,
    dedicatedAddressTotal: (state: IDedicatedAddressStore) =>
      state.dedicatedAddressTotal,
  },
  mutations: {
    SET_DEDICATION_ADDRESS_DATA(
      state: IDedicatedAddressStore,
      data: IDedicatedAddressList[]
    ) {
      state.dedicatedAddressList = data;
    },
    SET_DEDICATION_ADDRESS_TOTAL(state: IDedicatedAddressStore, data: number) {
      state.dedicatedAddressTotal = data;
    },
    RESET(state: IDedicatedAddressStore) {
      // eslint-disable-next-line no-unused-vars
      state.dedicatedAddressList = [];
      state.dedicatedAddressTotal = 0;
    },
  },
  actions: {
    setDedicatedAddressData(context: any, value: IDedicatedAddressList[]) {
      context.commit('SET_DEDICATION_ADDRESS_DATA', value);
    },
    setDedicatedAddressTotal(context: any, value: number) {
      context.commit('SET_DEDICATION_ADDRESS_TOTAL', value);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
