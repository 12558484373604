import RouterComponent from '@/components/router/Router.vue';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/invoice-summary',
      name: 'InvoiceSummary',
      component: () => import('@/pages/invoice-summary/InvoiceSummary.vue'),
    },
  ],
};
