import RouterComponent from '@/components/router/Router.vue';

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/order-summary',
      name: 'OrderSummary',
      component: () => import('@/pages/order-summary/OrderSummary.vue'),
    },
  ],
};
