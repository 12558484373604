import { ICheckoutItem } from '@/models/interfaces/checkout/ICheckout';
import { ICheckoutStore } from '@/models/interfaces/stores/ICheckoutStore';

export default {
  state: {
    checkoutsList: null,
    checkoutsMeta: {
      total: 0,
    },
    oneCheckout: {},
  },
  getters: {
    checkoutsList: (state: ICheckoutStore) => {
      if (!state.checkoutsList) return null;
      return state.checkoutsList.map((item: ICheckoutItem) => {
        item.created_at = item.createdAt;
        return item;
      });
    },
    checkoutsMeta: (state: ICheckoutStore) => state.checkoutsMeta,
    oneCheckout: (state: ICheckoutStore) => state.oneCheckout,
  },
  mutations: {
    SET_CHECKOUTS_LIST(state: ICheckoutStore, checkouts: ICheckoutItem[]) {
      state.checkoutsList = checkouts;
    },
    SET_CHECKOUTS_LIST_META(state: ICheckoutStore, meta: number) {
      state.checkoutsMeta.total = meta;
    },
    SET_ONE_CHECKOUT(state: ICheckoutStore, checkout: ICheckoutItem) {
      state.oneCheckout = checkout;
    },
    RESET(state: ICheckoutStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        checkoutsList: [],
        checkoutsMeta: {
          total: 0,
        },
        oneCheckout: {} as ICheckoutItem,
      };
    },
  },
  actions: {
    setCheckoutsList(context: any, checkouts: ICheckoutItem[]) {
      context.commit('SET_CHECKOUTS_LIST', checkouts);
    },
    setCheckoutsListMeta(context: any, meta: number) {
      context.commit('SET_CHECKOUTS_LIST_META', meta);
    },
    setOneCheckout(context: any, checkout: ICheckoutItem) {
      context.commit('SET_ONE_CHECKOUT', checkout);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
