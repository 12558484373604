import Vue from 'vue';
import Vuex from 'vuex';

import { IRootStore } from '@/models/interfaces/stores/IRootStore';
import baseStore from './modules/baseStore';
import authStore from './modules/authStore';
import balanceStore from './modules/balanceStore';
import withdrawStore from './modules/withdrawStore';
import paymentsStore from './modules/paymentsStore';
import checkoutsStore from './modules/checkoutsStore';
import invoicesStore from './modules/invoicesStore';
import userStore from './modules/userStore';
import metamaskStore from './modules/metamaskStore';
import whitelistDomainsStore from './modules/whitelistDomainsStore';
import volumeStore from './modules/volumeStore';
import transactionStore from './modules/transactionStore';
import statementStore from './modules/statementStore';
import exchangeStore from './modules/exchangeStore';
import merchantsStore from './modules/merchantsStore';
import payoutStore from './modules/payoutStore';
import routeStore from './modules/routeStore';
import callbacksStore from './modules/callbacksStore';
import dedicatedAddressStore from './modules/dedicatedAddressStore';
import dividendsStore from './modules/dividendsStore';

Vue.use(Vuex);

export default new Vuex.Store<IRootStore>({
  modules: {
    base: baseStore,
    auth: authStore,
    balance: balanceStore,
    withdraw: withdrawStore,
    payments: paymentsStore,
    checkouts: checkoutsStore,
    invoices: invoicesStore,
    users: userStore,
    metamask: metamaskStore,
    whitelistDomains: whitelistDomainsStore,
    volume: volumeStore,
    transaction: transactionStore,
    statement: statementStore,
    exchange: exchangeStore,
    merchants: merchantsStore,
    payouts: payoutStore,
    route: routeStore,
    callbacks: callbacksStore,
    dedicatedAddresses: dedicatedAddressStore,
    dividends: dividendsStore,
  },
});
