import { IRouteStore } from '@/models/interfaces/stores/IRouteStore';

export default {
  state: {
    parentRoute: null,
  },
  getters: {
    parentRoute: (state: IRouteStore) => state.parentRoute,
  },
  mutations: {
    SET_PARENT_ROUTE(state: IRouteStore, route: string) {
      state.parentRoute = route;
    },
    RESET(state: IRouteStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        parentRoute: null,
      };
    },
  },
  actions: {
    reset(context: any) {
      context.commit('RESET');
    },
    setParentRoute(context: any, data: any) {
      context.commit('SET_PARENT_ROUTE', data);
    },
  },
};
