import { IVerifyEmailResponse } from '@/models/interfaces/auth/IAuth';
import { IAuthStore } from '@/models/interfaces/stores/IAuthStore';

export default {
  state: {
    verificationData: null,
    backupCodes: null,
  },
  getters: {
    verificationData: (state: IAuthStore) => state.verificationData,
    backupCodes: (state: IAuthStore) => state.backupCodes,
  },
  mutations: {
    SET_VERIFICATION_DATA(state: IAuthStore, verificationData: string) {
      state.verificationData = verificationData;
    },
    SET_BACKUP_CODES(state: IAuthStore, codes: string) {
      state.backupCodes = codes;
    },
    RESET(state: IAuthStore) {
      // eslint-disable-next-line no-unused-vars
      state = {
        verificationData: null,
        backupCodes: null,
      };
    },
  },
  actions: {
    setVerificationData(context: any, value: IVerifyEmailResponse) {
      context.commit('SET_VERIFICATION_DATA', value);
    },
    setBackupCodes(context: any, codes: string) {
      context.commit('SET_BACKUP_CODES', codes);
    },
    reset(context: any) {
      context.commit('RESET');
    },
  },
};
