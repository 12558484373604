<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.425"
    height="15.515"
    viewBox="0 0 18.425 15.515"
  >
    <g transform="translate(0 0)">
      <path
        class="a"
        d="M95.1,466.76l-2.687-2.649a.39.39,0,0,0-.544,0L82.549,473.3l-2.415-2.382a.39.39,0,0,0-.544,0L76.9,473.571a.376.376,0,0,0,0,.536l5.374,5.3a.389.389,0,0,0,.544,0L95.1,467.3A.376.376,0,0,0,95.1,466.76Z"
        transform="translate(-76.792 -464.001)"
      ></path>
    </g>
    <path
      class="a"
      d="M5.486,15.4l-5.373-5.3a.375.375,0,0,1,0-.536L2.8,6.921a.391.391,0,0,1,.544,0L5.758,9.3,15.082.111a.388.388,0,0,1,.543,0l2.687,2.649a.375.375,0,0,1,0,.536L6.03,15.4a.389.389,0,0,1-.544,0Z"
      transform="translate(0 0)"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Tick',
  };
</script>

<style lang="scss" scoped>
  .a {
    fill: currentColor;
  }
</style>
